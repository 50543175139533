import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan, purple, lightBlue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { getAnnotationsForSalesDocument } from 'queries';
import { addAnnotation, updateAnnotation } from 'mutations';
*/

import { useQuery, useMutation, useSubscription } from 'urql';
import { getAllMeasurements} from 'queries_urql';
import { addAnnotation, updateMeasurementField  } from 'mutations_urql';
import { subscribeAnnotationsForParent } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from 'material-ui/Snackbar';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import ZStatusEditInputCell from '../core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FlashOnIcon from '@material-ui/icons/FlashOn';

import ReactTooltip from 'react-tooltip';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  ticket_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: green[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  tickets_avatar: {
    backgroundColor: red[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      backgroundColor: 'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: '#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  items: {}

}
*/

/////////////////////////////////////////

const ZMeasurementList = (props) => {
  const history = useHistory();
  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [userData, setUserData] = useLocalStorage('userData');
  const [selectionModel, setSelectionModel] = React.useState([]);

  const [alertState , setAlertState]  = React.useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const classes = useStyles();

  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'timestamp',
      title: 'Timestamp',
      headerName: 'Timestamp',
      minWidth: 180,
      editable: false,
      hide: false,
    },
    {
      field: 'mAxis',
      title: 'Axis',
      headerName: 'Axis',
      minWidth: 150,
      editable: false,
      hide: false,
    }, 
    {
      field: 'rms',
      title: 'RMS',
      headerName: 'RMS',
      minWidth: 220,
      editable: false,
      hide: false,
    },              
    {
      field: 'createdOn',
      title: 'Created',
      headerName: 'Created',
      type: 'date',
      minWidth: 180,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD HH:MM:SS">
         {params.row.createdOn}
        </Moment>
      )
    },
  ];


  const handleCellEditCommit = React.useCallback(({ id, field, value }) => {
    console.log('ZMeasurementList->handleCellEditCommit')
    let modifiedOn = moment().format();
    let user_id = userData.id;
    let _set = {};
    _set[field] = value;
    _set.modifiedOn = modifiedOn;

    let variables = {
      id: id,
      _set: _set,
      activities: [{
        user_id: user_id,
        parent_id: id,
        name: `measurement ${field} updated`,
        description: `${userData.alias} updated measurement ${field} to ${value}`,          
      }],
    }

    console.log(variables);
    
    updateFunction(variables).then((result)=>{
      console.log(result);

      if(result.error){
        console.log(`ERROR updating measurement: ${result.error}`)
        setAlertState({...alertState, open: true, message: result.error, severity: 'error'});

      } else {
        console.log(`measurement update [ ${_set} ]`)
        console.log(result)
        
        setAlertState({...alertState, open: true, message: 'Successfully updated measurement', severity: 'success'})
      }
    })

  },[]);

  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  const handleSelectionModelChange = () => {
    if(props.onSelectionModelChange){
      console.log('ZMeasurementsList->handleSelectionModelChange')
      console.log(selectionModel)
      props.onSelectionModelChange(selectionModel);
    }
  }

  const headerMenu = (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorHeader}
        keepMounted
        open={Boolean(anchorHeader)}
        onClose={handleCloseHeader}
      >
        <MenuItem onClick={handleCloseHeader}>New Note</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
        <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
      </Menu>
    </div>  
  )

  const loadDataAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          console.log('ZSensorView->selectedIDs')
          console.log(selectionModel)
          if(props.onSelectionModelSelect){
            props.onSelectionModelSelect(selectionModel)
          }
        }}
      >
        <CloudDownloadIcon />
      </IconButton>      
    </div>     
  )

  console.log(props)

  let items = []
  let query = getAllMeasurements
  let variables = {}

  let skip_query = false;
  let skip_sub = true;
  let title = "Measurements"

  if(props.static){
    skip_query = true;
  }

  if(props.realtime === undefined || props.live === undefined){
    skip_sub = true;
  }

  if( props.title){
    title = props.title
  }

  if( props.items){
    skip_query = true;
  }

  if( props.client){
    variables = { client_id: props.client.id }
  }

  const [ result, reexecuteQuery ]  = useQuery({
    query: query,
    variables: variables,
    pause: skip_query,
  });

  const [updateResult, updateFunction] = useMutation(updateMeasurementField);

  console.log('result');
  console.log(result)

  const { data, fetching, error } = result;


  if(skip_query === false){
    if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
    if (error) return `Error! ${error.message}`;
  }

  if (skip_query === true){
    items = props.items !== undefined ? props.items : props.data;
  } else {
    items = data.zeus_measurement
  }

  console.log('ZMeasurementList->data');
  console.log(data);

  //console.log('ZMeasurementList->items');
  //console.log(items);

  let defaultAction = (
    <Fab
      //color="secondary"
      aria-label="add"
      size="small"
      onClick={handleCardHeaderClick}
      data-tip="notes actions"
      data-for="tickets_list_tooltip" 
    >
      <MoreVertIcon />
      {headerMenu}
    </Fab>    
  )

  //////////////////////////////////

  return (
    <div>
      {/* alert component */}
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        key="alert_bar"
        onClose={()=>setAlertState({...alertState, open: false})}
      >   
        <Alert severity={alertState.severity} onClose={()=>setAlertState({...alertState, open: false})}>
          {alertState.message}
        </Alert>       
      </Snackbar> 
      {/* main component */}
      <Card className={classes.root}>
        <CardActionArea>
          <CardHeader
            avatar={
              <Avatar aria-label="ticket" className={classes.tickets_avatar}>
                <FlashOnIcon />
              </Avatar>
            }      
            action={loadDataAction}         
            title={title}
            titleTypographyProps={{variant:'h5'}}
          />
          <CardContent>
            <Paper className={classes.ticket_row}>
              <DataGrid autoHeight
                rows={items}
                columns={columns}
                pageSize={props.pageSize ?? 20}
                rowsPerPageOptions={[5]}
                checkboxSelection={true}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log('newSelectionModel');
                  console.log(newSelectionModel);
                  setSelectionModel(newSelectionModel);
                  //handleSelectionModelChange(newSelectionModel);
                }}
                selectionModel={selectionModel}                  
                disableSelectionOnClick={true}
                density="compact"
                options={{
                  toolbar: true,
                  filtering: true,
                  search: true,
                  grouping: true,
                  exportButton: true,
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                onCellEditCommit={handleCellEditCommit}
              />
            </Paper>
          </CardContent>
        </CardActionArea>
        <ReactTooltip id="measurements_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZMeasurementList;


/*

    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/measurement/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },


                getRowClassName={(params) =>
                  clsx('super-app', {
                    negative: params.row.status === 'pending',
                    positive: params.row.status === 'active',
                  })
                }
                
*/
