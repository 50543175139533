/*
create or replace function zeus.get_new_job_number(prefix varchar)
returns varchar as $$
select 
prefix || A.nextval
from (select nextval('zeus.jobboard_entry_job_integer_seq')) as A $$
language SQL volatile

create or replace function zeus.get_new_job_number(prefix varchar)
returns TABLE(job_number varchar) as $$
select 
prefix || A.nextval
from (select nextval('zeus.jobboard_entry_job_integer_seq')) as A $$
language SQL volatile

select zeus.get_new_job_number('CP')

query {
	get_new_job_number(args: {prefix: "CP"}){
		job_number
	}
}
*/

// args:{prefix: "C"}
export const getNewJobNumber = `
query getNewJobNumber {
	zeus_get_new_job_number(args = {}) {
	  id
	}
}
`;


export const salesStatsQuery = `
  query salesStatsQuery($accepted_year: float8 = "", $accepted_month: float8 = "") {
  zeus_monthly_sales_stats(where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}}}) {
    accepted_month
    accepted_year
    avg_time_to_accept
    max_amount
    max_time_to_accept
    min_amount
    min_time_to_accept
    quote_count
    total_amount
  }
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
}
`;


export const salesFunnelStatsQuery = `
query salesFunnelStatsQuery($funnel: String = "") {
  zeus_funnel_sales_stats(where: {funnel: {_eq: $funnel}}) {
    funnel
    avg_time_to_accept
    max_time_to_accept
    min_time_to_accept
    total_amount
    max_amount
    min_amount
    quote_count
  }
}
`;


export const salesBySupplierAndFunnelQuery = `
query salesBySupplierAndFunnelQuery($supplier: String = "", $funnel: String = "") {
  zeus_quotation_view(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id    
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    acceptedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  zeus_supplier_funnel_sales_stats(where: {_and: {funnel: {_eq: $funnel}, supplier: {_ilike: $supplier}}}) {
    supplier
    funnel
    avg_time_to_accept
    max_time_to_accept
    min_time_to_accept
    quote_count
    total_amount
    min_amount
    max_amount
  }
}
`;


export const salesBySupplierAndFunnelQuery_1 = `
query salesBySupplierAndFunnelQuery($supplier: String = "", $funnel: String = "", $status: String = "") {
  zeus_quotation_view(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: $status}}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id    
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: $status}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {name: {_ilike: $supplier}}, funnel: {_eq: $funnel}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  zeus_supplier_funnel_sales_stats(where: {_and: {funnel: {_eq: $funnel}, supplier: {_ilike: $supplier}}}) {
    supplier
    funnel
    avg_time_to_accept
    max_time_to_accept
    min_time_to_accept
    quote_count
    total_amount
    min_amount
    max_amount
  }
}
`;



export const xxx = `
query salesMonthlyBySupplierQuery($accepted_year: float8 = "2021", $accepted_month: float8 = "9", $supplier: String = "CTC") {
  zeus_monthly_supplier_sales_view(where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, supplier: {_eq: $supplier}}}) {
    accepted_month
    accepted_year
    avg_time_to_accept
    max_amount
    max_time_to_accept
    min_amount
    min_time_to_accept
    quote_count
    total_amount
    supplier
  }
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
}
`;


export const salesBySupplierAndMonthQuery = `
query salesBySupplierAndMonthQuery($supplier: String = "", $accepted_year: float8 = "", $accepted_month: float8 = "", $status: String = "") {
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, document_type: {_eq: "Quotation"}, status: {_eq: $status}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, status: {_eq: $status}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  zeus_monthly_supplier_sales_view(where: {_and: {supplier: {_eq: $supplier}}, accepted_year: {_eq: $accepted_year}, accepted_month: {_eq: $accepted_month}}) {
    accepted_month
    accepted_year
    avg_time_to_accept
    max_amount
    max_time_to_accept
    min_amount
    min_time_to_accept
    quote_count
    supplier
    total_amount
  }
}
`;


export const pendingSalesBySupplierAndMonthQuery = `
query pendingSalesBySupplierAndMonthQuery($supplier: String = "", $created_year: float8 = "", $created_month: float8 = "", $status: String = "") {
  zeus_quotation_view(order_by: {createdOn: desc}, where: {_and: {created_year: {_eq: $created_year}, created_month: {_eq: $created_month}, document_type: {_eq: "Quotation"}, status: {_eq: $status}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    funnel
    description
    status
    document_type
    amount
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_code
    supplier_id
  }
  zeus_sales_document(where: {_and: {name: {_ilike: $supplier}}, status: {_eq: $status}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    funnel
    amount
    description
    createdOn
    closedOn
    status
    preparedBy
    client {
      id
      name
      region: address(path: "region.province")
    }
  }
  won_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, status: {_eq: "accepted"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  lost_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, status: {_eq: "closed"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_aggregate: zeus_quotation_view_aggregate(where: {_and: {supplier_code: {_eq: $supplier}}, created_year: {_eq: $created_year}, created_month: {_eq: $created_month}, status: {_eq: "pending"}}) {
    aggregate {
      count
      avg {
        amount
      }
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const pendingSalesStatsQuery = `
query pendingSalesStatsQuery($created_year: float8 = "", $created_month: float8 = "") {
  zeus_monthly_pending_sales_stats(where: {_and: {created_year: {_eq: $created_year}, created_month: {_eq: $created_month}}}) {
    created_year
    created_month
    quote_count
    total_amount
    max_amount
    min_amount
    avg_amount
  }
}
`;


export const pendingSalesFunnelStatsQuery = `
query pendingSalesFunnelStatsQuery($funnel: String = "") {
  zeus_funnel_pending_sales_stats(where: {funnel: {_eq: $funnel}}, order_by: {funnel: asc}) {
    funnel
    quote_count
    total_amount
    max_amount
    min_amount
    avg_amount
  }
}
`;


export const monthlySalesQuery = `
query monthlySalesQuery($accepted_year: float8 = "") {
  zeus_monthly_sales_view(where: {accepted_year: {_eq: $accepted_year}}) {
    accepted_year
    accepted_month
    avg_days_to_accept
    avg_days_to_close
    total_amount
  }
}
`;


export const monthlySupplierSalesQuery = `
query monthlySalesQuery($accepted_year: float8 = "", $supplier: String = "") {
  zeus_monthly_supplier_sales_view(where: {_and: {accepted_year: {_eq: $accepted_year}, supplier: {_eq: $supplier}}}) {
    __typename
    supplier
    accepted_year
    accepted_month
    quote_count
    total_amount
    max_amount
 		min_amount
 		avg_time_to_accept
    max_time_to_accept
  	min_time_to_accept
  }
  zeus_quotation_view(where: {_and: {accepted_year: {_eq: $accepted_year}, supplier_code: {_eq: $supplier}, document_type: {_eq: "Quotation"}}}) {
    __typename
    id
    name
    description
    funnel
    amount
    document_type
    status
    supplier_code
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    job_id
    modifiedOn
    parent_id
    supplier_id
  }
}
`;


//getAllAssetSurveys
export const getAllAssetSurveys = `
query getAllAssetSurveys {
  zeus_asset_survey {
    __typename
    id
    severity
    recommendations
    notification_num
    modifiedOn
    summary
    status
    theDate
    createdOn
    findings_from_work_completed
    condition_value
    condition {
      __typename
      id
      name
      descriptors {
        __typename
        id
        name       
      }
    }
    descriptor {
      __typename
      id
      name
    }    
    asset {
      __typename
      id
      name
      description
      criticality
      currentCondition
      createdOn
      status
      client {
        __typename
        id
        name       
      }
      client_node{
        __typename
        id
        name
        node_type{
          __typename
          name
        }
      }
    }
    images (order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      status
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
  }
}
`;

export const monthlySalesTotalQuery = `
query monthlySalesTotalQuery($accepted_year: float8 = "", $status: String = "") {
  zeus_monthly_sales_view_aggregate(where: {accepted_year: {_eq: $accepted_year}}) {
    aggregate {
      sum {
        total_amount
      }
    }
  }
}
`;


export const wonSalesTotalQuery = `
query wonSalesTotalQuery($accepted_year: float8 = "") {
  zeus_quotation_view_aggregate(where: {_and: {accepted_year: {_eq: $accepted_year}, status: {_eq: "accepted"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
      avg {
        amount
      }
    }
  }
}
`;


export const wonSalesFunnelQuery = `
query wonSalesFunnelQuery($funnel: String = "") {
  zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "accepted"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
      avg {
        amount
      }
    }
  }
}
`;


export const lostSalesTotalQuery = `
query lostSalesTotalQuery($closed_year: float8 = "") {
  zeus_quotation_view_aggregate(where: {_and: {closed_year: {_eq: $closed_year}, status: {_eq: "closed"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const lostSalesFunnelQuery = `
query lostSalesFunnelQuery($funnel: String = "") {
  zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "closed"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const salesFunnelQuery = `
query salesFunnelQuery($funnel: String = "") {
  lost_sales_stats: zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "closed"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_sales_stats: zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "pending"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  accepted_sales_stats: zeus_quotation_view_funnel_aggregate(where: {_and: {funnel: {_eq: $funnel}, status: {_eq: "accepted"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}
`;


export const getSurveyStatsForClientQuery = `
query getSurveyStatsForClientQuery($client_id: uuid = "") {
  good_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "Good"}}}) {
    aggregate {
      count
    }
  }
  acceptable_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "Acceptable"}}}) {
  	aggregate {
      count
    }
  }
  reportable_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "Reportable"}}}) {
    aggregate {
      count
    }
  }
  unsatisfactory_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "Unsatisfactory"}}}) {
    aggregate {
      count
    }
  }
  unacceptable_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "Unacceptable"}}}) {
    aggregate {
      count
    }
  }
  nostatus_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "No Status"}}}) {
    aggregate {
      count
    }
  }
  noaccess_assets: zeus_asset_aggregate(where: {_and: {client_id: {_eq: $client_id}, currentCondition: {_eq: "No Access"}}}) {
    aggregate {
      count
    }
  }
}
`;


export const getFunnelsQuery = `
query getFunnelsQuery {
  zeus_sales_document(distinct_on: funnel) {
    funnel
  }
}
`;


export const getAllClientsX = `
query getAllClients {
  zeus_client(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
  }
}`;


export const getAllClientNodeTypes = `
query getAllClientNodeTypes {
  zeus_node_type(order_by: {name: asc}){
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
  }
}
`;


export const getAllComponents = `
query getAllComponents {
  zeus_component_type(order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
  }
}
`;


export const getAllClients = `
query getAllClients {
  zeus_client(where: {status: {_eq: "active"}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
    assets_aggregate {
      aggregate {
        count
      }
    }
    active_routes: routes_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    pending_quotations: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
  }
}`;


export const getAllUserRoles = `
query getAllUserRoles {
  zeus_zeus_role(order_by: {name: asc}) {
    id
    name
    description
    createdOn
    status
  }
}
`;


export const getClient = `
query getClient($id: uuid = "") {
  zeus_client_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    address(path: "label")
    region: address(path: "region.province")
    active_contacts: contacts(order_by: {firstName: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      firstName
      lastName
      email
      status
    }
    all_contacts: contacts(order_by: {firstName: asc}) {
      __typename
      id
      firstName
      lastName
      email
      status
    }    
    gateways(order_by: {name: asc}) {
      __typename
      id
      name
      alias
      description
      createdOn
      status    
      sensors_aggregate {
        aggregate {
          count
        }
      }   
      parent {
        id
        name
        description
        status
      }           
    }
    sensors(order_by: {name: asc}) {
      id
      name
      alias
      description
      createdOn
      modifiedOn
      lastMeasurement
      status
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }
    paired_sensors: sensors(where: {gateway_id: {_is_null: false}}, order_by: {name: asc}) {
      id
      name
      alias
      description
      createdOn
      modifiedOn
      lastMeasurement
      status
      gateway {
        id
        name
        alias
        description
        createdOn
        status
      }
    }     
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    } 
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    assets_aggregate {
      aggregate {
        count
      }
    }
    active_routes: routes_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    jobs(order_by: {createdOn: desc}){
      __typename
      id
      name
      description
      createdOn
      quoteAmount
      status
      scheduled
      poAmount
      invoiceNum
      invoiceDate
      invoiceAmount
      customerPO
      createdBy
      companyName
      completedOn
      primacQuoteName
      primacPO
      isTravelBooked
      serviceType
      technician {
        __typename
        id
        alias
      }  
      purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate
      }            
    }
    quotations: sales_documents(where: { document_type: {_eq: "Quotation"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      amount
      funnel
      description
      status
      createdOn
      modifiedOn
      closedOn
      acceptedOn
      preparedBy
      revision
      document_type
      contact {
        __typename
        id
        firstName
        lastName
        status
        createdOn
      }
    } 
    purchaseOrders: sales_documents(where: { document_type: {_eq: "PurchaseOrder"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      amount
      funnel
      description
      status
      createdOn
      modifiedOn
      closedOn
      acceptedOn
      preparedBy
      revision
      document_type
      contact {
        __typename
        id
        firstName
        lastName
        status
        createdOn
      }
      invoices: sales_documents(where: { document_type: {_eq: "Invoice"}}, order_by: {createdOn: desc}) {
        __typename
        id
        name
        amount
        funnel
        description
        status
        createdOn
        modifiedOn
        closedOn
        acceptedOn
        preparedBy
        revision
        document_type
        contact {
          __typename
          id
          firstName
          lastName
          status
          createdOn
        }
      }        
    }  
    invoices: sales_documents(where: { document_type: {_eq: "Invoice"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      amount
      funnel
      description
      status
      createdOn
      modifiedOn
      closedOn
      acceptedOn
      preparedBy
      revision
      document_type
      contact {
        __typename
        id
        firstName
        lastName
        status
        createdOn
      }
    }   
    pending_quotations_aggregate: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    routes(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
      client_node {
        __typename
        id
        name
        description
      }
    }
    client_nodes(order_by: {name: asc}) {
      __typename
      id
      name
      description
      createdOn
      status
      active_assets: assets_aggregate(where: {status: {_eq: "active"}}) {
        aggregate {
          count
        }
      }  
      reportable_assets: assets_aggregate(where: {_or: [{currentCondition: {_eq: "Reportable"}}, {currentCondition: {_eq: "Unsatisfactory"}}, {currentCondition: {_eq: "Unacceptable"}}]}) {
        aggregate {
          count
        }
      }        
    }
    assets(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      currentCondition
      criticality
      components {
        __typename
        id
        name
        description
        createdOn
        modifiedOn
        status
      }
      surveys(order_by: {theDate: desc}, limit: 12) {
        __typename
        id
        theDate
        status
        severity
        recommendations
        findings_from_work_completed
        createdOn
        summary
        component {
          __typename
          id
          name
        }
        technician {
          __typename
          id
          alias
        }
      }      
    }
    asset_surveys {
      __typename
      id
      summary
      status
      theDate
      recommendations
      severity
      createdOn
      asset {
        __typename
        id
        name
        description
        currentCondition
        status
      }
    }
  }
}
`;


export const getAllAnnotations = `
query getAllAnnotations {
  zeus_annotation(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAllTickets = `
query getAllTickets {
  zeus_ticket(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAllMeasurements = `
query getAllMeasurements {
  zeus_measurement(order_by: {createdOn: desc}) {
    __typename
    id
    timestamp
    mAxis
    apath
    xpath
    rms
    description
    status
    createdOn
  }
}`;


export const getRMSMeasurementsForSensor = `
query getRMSMeasurementsForSensor($sensor_id: uuid = "", $offset: Int = 0, $limit: Int = 100) {
  x_rms: zeus_measurement_rms_view(offset: $offset, limit: $limit, order_by: {timestamp: asc}, where: {_and: [{sensor_id: {_eq: $sensor_id}}, {mAxis: {_eq: "x"}}]}) {
    timestamp
    rms
  }
  y_rms: zeus_measurement_rms_view(offset: $offset, limit: $limit, order_by: {timestamp: asc}, where: {_and: [{sensor_id: {_eq: $sensor_id}}, {mAxis: {_eq: "y"}}]}) {
    timestamp
    rms
  }
  z_rms: zeus_measurement_rms_view(offset: $offset, limit: $limit, order_by: {timestamp: asc}, where: {_and: [{sensor_id: {_eq: $sensor_id}}, {mAxis: {_eq: "z"}}]}) {
    timestamp
    rms
  }  
}`;


export const getAllFlraTasks = `
query getAllFlraTasks {
  zeus_flra_task(order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
  }
}`;


export const getAnnotationsForSalesDocument = `
query getAnnotationsForSalesDocument($sales_document_id: uuid = "") {
  zeus_annotation(where: {_and: {sales_document_id: {_eq: $sales_document_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;

export const getInvoicesForSalesDocument = `
query getInvoicesForSalesDocument($job_id: uuid = "") {
  zeus_sales_document(where: {_and: {document_type: {_eq: "Invoice"}, job_id: {_eq: $job_id}}}){
    __typename
    id
    name
    amount
    description
    status
    theDate
    createdOn
    jobBoardEntry {
      __typename
      id
      name
      description
      companyName
      status
    }
  }
}
`


export const getContactsForClient = `
query getContactsForClient($client_id: uuid = "") {
  zeus_contact(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getAssetsForClient = `
query getAssetsForClient($client_id: uuid = "") {
  zeus_asset(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    currentCondition
    criticality
    surveys(order_by: {theDate: desc}, limit: 12) {
      __typename
      id
      theDate
      status
      severity
      recommendations
      findings_from_work_completed
      createdOn
      summary
      component {
        __typename
        id
        name
      }
      technician {
        __typename
        id
        alias
      }
    } 
    client_node {
      __typename
      id
      name
      description
      status
      node_type_id
    }
  }
}`;


export const getToolsView = `
query getToolsView {
  zeus_tool_node(owhere: {_and: {status: {_eq: "active"}, node_type: {_eq: "ToolLocker"}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    active_toolkits: child_nodes(where: {_and: {status: {_eq: "active"}, node_type: {_eq: "ToolKit"}}}, order_by: {name: asc}){
      __typename
      id
      name
      createdOn
      status
    }
    active_tools: tools(where: {status: {_eq: "active"}}, order_by: {name: asc}){
      __typename
      id
      name
      createdOn
      status     
    }
  }

  zeus_tool(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    lastCheckedIn
    lastCheckedOut
    parent {
      __typename
      id
      name
      description
      node_type
      status          
    }
  }
}`;


export const getAllToolLockers = `
query getAllToolLockers {
  tool_lockers: zeus_tool_node( where: {node_type: {_eq: "ToolLocker"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    child_nodes(where: {_and: { node_type: {_eq: "ToolKit"}}}){
      __typename
      id
      name
      createdOn
      status          
    }
    active_tools: locker_tools {
      __typename
      id
      name
      description
      createdOn
      status    
    }    
  }
  tool_kits: zeus_tool_node( where: {node_type: {_eq: "ToolKit"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    status
    lat
    lng
    lastCheckedIn
    lastCheckedOut     
  }  
  zeus_tool(order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    status
    createdOn
    lat
    lng
    lastCheckedIn
    lastCheckedOut
  }
}`;


export const getAllToolLockerNames = `
query getAllToolLockerNames {
  zeus_tool_node( where: {node_type: {_eq: "ToolLocker"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
  }
}`;


export const getAllToolKits = `
query getAllToolKits {
  zeus_tool_node(where: {node_type: {_eq: "ToolKit"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    lastCheckedOut
    lastCheckedIn
    address
    createdOn
    active_tools: tools(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_tools: tools(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }
  }
}`;


export const getAllToolKitsDialog = `
query getAllToolKitsDialog {
  zeus_tool_node(where: {node_type: {_eq: "ToolKit"}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    lastCheckedOut
    lastCheckedIn
    address
    createdOn
    active_tools: tools(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_tools: tools(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }
  }
}`;

export const getAllToolKitsForLocker = `
query getAllToolKitsForLocker($node_id: uuid = "", $node_type = "ToolLocker") {
  zeus_tool_node(where: {_and: {node_id: {_eq: $locker_id}, node_type: {_eq: $node_type}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    lastCheckedOut
    lastCheckedIn
    address
    createdOn
    parent {
      __typename
      id
      name
      description
      node_type
      status          
    }
    active_tools: tools(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_tools: tools(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }
  }
}`;


export const getToolKitsForNode = `
query getToolKitsForNode($node_id: uuid = "", $node_type: String = "ToolKit") {
  zeus_tool_node(where: {_and: {node_id: {_eq: $node_id}, node_type: {_eq: $node_type}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    lastCheckedOut
    lastCheckedIn
    address
    createdOn
    parent {
      __typename
      id
      name
      description
      node_type
      status          
    }
    active_tools: tools(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_tools: tools(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }
  }
}`;


export const getAllToolNodes = `
query getAllToolNodes {
  zeus_tool_node(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    lastCheckedOut
    lastCheckedIn
    address
    createdOn
    status
    node_type
    active_tools: tools(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_tools: tools(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }
  }
}`;


export const getAllTools = `
query getAllTools {
  zeus_tool(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    lastCheckedIn
    lastCheckedOut
    parent {
      __typename
      id
      name
      description
      node_type
      status          
    }
  }
}`;


export const getAllToolsForLocker = `
query getAllToolsForLocker($node_id: uuid = "") {
  zeus_tool(where: {node_id: {_eq: $node_id}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    lastCheckedIn
    lastCheckedOut
    parent {
      __typename
      id
      name
      description
      node_type
      status          
    }
  }
}`;


export const getAllToolsForNode = `
query getAllToolsForNode($node_id: uuid = "") {
  zeus_tool(where: {parents: {node: {id: {_eq: $node_id}}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    lastCheckedIn
    lastCheckedOut
    status
    lat
    lng
    parents {
      node {
        id
        name
        description
        status
      }
    }
  }
}
`;


export const getAllToolsForKit = `
query getAllToolsForKit($node_id: uuid = "") {
  zeus_tools(where: {node_id: {_eq: $node_id}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    location
    address
    createdOn
    status
    parent {
      __typename
      id
      name
      description
      node_type
      status      
    }
  }
}`;


export const getToolNode = `
query getToolNode($id: uuid = "") {
  zeus_tool_node_by_pk(id: $id) {
    __typename
    id
    name
    description
    node_type
    createdOn
    address
    location
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    status
    nfcTag
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }     
    parent {
      __typename
      id
      name
      description
      node_type
      createdOn
      status
      lat
      lng
      tools {
        tool {
          __typename
          id
          name
          description
          serialNumber
          status
        }
      }   
      locker_tools {
        __typename
        id
        name
        description
        serialNumber
        status
        createdOn
      }   
    }
    child_nodes {
      __typename
      id
      name
      description
      address
      location
      status
      node_type
      lat
      lng
      bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
        __typename
        id
        theDate
        description
        createdOn
        status
      }
    }
    active_locker_tools: locker_tools(where: {status: {_eq: "ready"}}) {
      __typename
      id
      name
      description
      status
      lastCheckedIn
      lastCheckedOut
      createdOn
      lat
      lng
    }
    all_locker_tools: locker_tools {
      __typename
      id
      name
      description
      status
      lastCheckedIn
      lastCheckedOut
      createdOn
      lat
      lng
    }    
    active_tools: tools(where: {status: {_eq: "ready"}}) {
      tool{
        __typename
        id
        name
        description
        status
        lastCheckedIn
        lastCheckedOut
        createdOn
        lat
        lng
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
          user {
            __typename
            id
            alias
          }
        }
      }
    }
    all_tools: tools {
      tool{
        __typename
        id
        name
        description
        status
        lastCheckedIn
        lastCheckedOut
        createdOn
        lat
        lng
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
           user {
            __typename
            id
            alias
          }
        }
      }
    }
    tools_inuse_aggregate: tools_aggregate(where: {tools: {_and: {parents: {node_id: {_eq: $id}}, status: {_eq: "in-use"}}}}) {
      aggregate {
        count
      }
    }
    tools_aggregate {
      aggregate {
        count
      }
    }
    usage(order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      status
      createdOn
      description
      lat
      lng
      job {
        __typename
        id
        name
        description
      }
      user {
        __typename
        id
        firstName
        lastName
        alias
      }
      locations (order_by: {createdOn: asc}) {
        __typename
        id
        lat
        lng
        createdOn
      } 
    }
    usage_count: usage_aggregate(where: {status: {_eq: "check-out"}}) {
      aggregate {
        count
      }
    }
    usage_aggregate {
      aggregate {
        count
      }
    }
    images (order_by: {createdOn: desc}, limit: 10){
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }      
    }
    annotations (order_by: {createdOn: desc}){
      __typename
      id
      name
      createdOn
      description
      user {
        __typename
        id
        alias
      }
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      user {
        __typename
        id
        alias
      }
    }
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    attachments(order_by: {createdOn: desc}){
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      url
      src
      parent_id
      user {
        __typename
        id
        alias
      }
    }
  }
}
`;

export const getCheckedOutToolsForUser = `
query getCheckedOutToolsForUser($id: uuid!) {
  zeus_tool(distinct_on: id, where: {_and: [
    {usage: {user_id: {_eq: $id}}}, 
    {status: {_eq: "in-use"}}
  ]}) {
    __typename
    id
    name
    status
    lastCheckedOut
    lat
    lng
    usage(order_by: {createdOn: desc}, limit: 1) {
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
      user {
        __typename
        id
        alias
      }      
    }
  } 
}
`;


export const getCheckedOutToolNodesForUser = `
query getCheckedOutToolNodesForUser($id: uuid!) {
  zeus_tool_node(distinct_on: id, where: {_and: [
    {usage: {user_id: {_eq: $id}}}, 
    {status: {_eq: "in-use"}}
    ]}) {
    __typename
    id
    name
    status
    lastCheckedOut
    lat
    lng
    node_type
    usage(order_by: {createdOn: desc}, limit: 1) {
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
      user {
        __typename
        id
        alias
      }
    }
  }
}
`;

export const getLowBalancePurchaseOrders = `
query getLowBalancePurchaseOrders {
  zeus_sales_document(order_by: {createdOn: desc}, where: {_and: {document_type: {_eq: "PurchaseOrder"}, status: {_eq: "active"}}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
    client {
      __typename
      id
      name
      description
    }
    jobBoardEntry {
      __typename
      id
      name
      description
    }        
    invoices: sales_documents(where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      createdOn
      closedOn
      status
    }
  }
}`;


export const getOpenTickets = `
query getOpenTickets {
  zeus_ticket(where: {status: {_eq: "active"}}, order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    resolution
    closedOn
    createdOn
    status  
    user {
      __typename
      id
      firstName
      lastName
      alias
      email
      description
      status
      createdOn 
    } 
    closer {
      __typename
      id
      firstName
      lastName
      alias
      email
      description
      status
      createdOn
    }
    tool {
      __typename
      id
      name
      description
      status
      createdOn
      lat
      lng
      lastCheckedIn
      lastCheckedOut
    }
    toolNode {
      __typename
      id
      name
      description
      status
      createdOn
      lat
      lng
      lastCheckedIn
      lastCheckedOut
    }
  }
}
`;

export const getTicket = `
query getTicket($id: uuid = "") {
  zeus_ticket_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    activities {
      __typename
      id
      name
      description
      createdOn
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    images {
      __typename
      id
      name
      description
      src
      lng
      lat
      img
      createdOn
      user {
        __typename
        id
        alias
      }
    }
    images_aggregate {
      aggregate {
        count
      }
    }
    user {
      __typename
      id
      alias
    }
    closer {
      __typename
      id
      alias
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      parent_id
      user {
        __typename
        id
        alias
      }      
    }
  }
}
`;

/*
  // tool_status
  status = "check-out"
  status = "check-in"
  // tool
  status = "retired"
  status = "out-of-service"
  status = "out-for-calibration"
  status = "ready"
  status = "in-use"
*/
export const getTool = `
query getTool($id: uuid = "") {
  zeus_tool_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    nfcTag
    locker {
      __typename
      id
      name
      description
      node_type
      status
      location
      address
      lat
      lng
      createdOn
    }    
    parents {
      node {
        __typename
        id
        name
        description
        node_type
        status   
        createdOn   
      }
    } 
    usage (order_by: {createdOn: desc}) {
      __typename
      id
      status
      createdOn
      description
      lat
      lng
      job {
        __typename
        id
        name
        description
        companyName
        status
      }
      user {
        __typename
        id
        alias
        firstName
        lastName
      }
      locations (order_by: {createdOn: asc}) {
        __typename
        id
        lat
        lng
        createdOn
      }    
    } 
    usage_count: usage_aggregate(where: {status: {_eq: "check-out"}}) {
      aggregate {
        count
      }
    }
    usage_aggregate {
      aggregate {
        count
      }
    }   
    images (order_by: {createdOn: desc}, limit: 10) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    annotations {
      __typename
      id
      name
      createdOn
      description
    }
    bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      user {
        __typename
        id
        alias
      }
    }
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }    
    attachments(order_by: {createdOn: desc}){
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      url
      src
      parent_id
      user {
        __typename
        id
        alias
      }
    }      
  }
  zeus_jobboard_entry(where: {status: {_eq: "active"}}, order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    status
    createdBy
    createdOn
  }  
  existing_tool_names: zeus_tool{
    __typename
    id
    name
  } 
  existing_serial_numbers: zeus_tool{
    __typename
    id
    serialNumber
  }
}`;


export const getUsageForNode = `
query getUsageForNode($node_id: uuid = "") {
  zeus_tool_user_status(where: {_and: {node_id: {_eq: $node_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    status
    description
    createdOn
    lat
    lng
    job {
      __typename
      id
      name
      description
    }
    user {
      __typename
      id
      alias
    }
  }
}`;


export const getUsageForTool = `
query getUsageForTool($tool_id: uuid = "") {
  zeus_tool_user_status(where: {_and: {tool_id: {_eq: $tool_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    status
    description
    createdOn
    lat
    lng
    job {
      __typename
      id
      name
      description
    }
    user {
      __typename
      id
      alias
    }
  }
}`;


export const getAllToolUsage = `
query getAllToolUsage($tool_id: uuid = "") {
  zeus_tool_user_status(order_by: {createdOn: desc}) {
    __typename
    id
    status
    description
    createdOn
    lat
    lng
    job {
      __typename
      id
      name
      description
    }
    user {
      __typename
      id
      alias
    }
  }
}`;


export const getBookingForNode = `
query getBookingForNode($id: uuid = "") {
  zeus_tool_user_booking(where: {node_id: {_eq: $id}}) {
    __typename
    id
    description
    createdOn
    theDate
    status  
    user {
      __typename
      id
      alias
      firstName
      lastName
      status
    }
    node {
      __typename
      id
      name
      description
      lastCheckedIn
      lastCheckedOut
      lat
      lng
      node_type
      createdOn
      status
      parent {
        __typename
        id
        name
        node_type
        status
      }
      tools {
        tool {
          __typename
          id
          name
          description
          createdOn
          status
          lastCheckedIn
          lastCheckedOut
          lat
          lng
        }
      }
    }
  }
}`;


export const getBookingForTool = `
query getBookingForTool($id: uuid = "") {
  zeus_tool_user_booking(where: {tool_id: {_eq: $id}}) {
    __typename
    id
    description
    createdOn
    theDate
    status
    user {
      __typename
      id
      alias
      firstName
      lastName
      status
    }
    tool {
      __typename
      id
      name
      description
      status
      createdOn
      lat
      lng
      usage {
        __typename
        id
        description
        lat
        lng
        createdOn
        status
        user {
          __typename
          id
          alias
          firstName
          lastName
        }
      }
    }
  }
}`;


export const getToolNodeEventView = `
query getToolNodeEventView($id: uuid = "") {
  zeus_tool_node_by_pk(id: $id) {
    __typename
    id
    name
    description
    node_type
    createdOn
    address
    location
    lastCheckedOut
    lastCheckedIn
    lat
    lng
    status
    parent {
      __typename
      id
      name
      description
      node_type
      createdOn
      status
      lat
      lng
    }
    child_nodes {
      __typename
      id
      name
      description
      address
      location
      status
      node_type
      lat
      lng
    }
    active_tools: tools(where: {status: {_eq: "ready"}}) {
      tool{
        __typename
        id
        name
        description
        status
        lastCheckedIn
        lastCheckedOut
        createdOn
        lat
        lng
      }
    }
    all_tools: tools {
      tool{
        __typename
        id
        name
        description
        status
        lastCheckedIn
        lastCheckedOut
        createdOn
        lat
        lng
      }
    }
    tools_inuse_aggregate: tools_aggregate(where: {tools: {_and: {parents: {node_id: {_eq: $id}}, status: {_eq: "in-use"}}}}) {
      aggregate {
        count
      }
    }
    tools_aggregate {
      aggregate {
        count
      }
    }
    usage(order_by: {createdOn: desc}) {
      __typename
      id
      status
      createdOn
      description
      lat
      lng
      job {
        __typename
        id
        name
        description
      }
      user {
        __typename
        id
        alias
      }
      locations (order_by: {createdOn: asc}) {
        __typename
        lat
        lng
        createdOn
      } 
    }
    usage_count: usage_aggregate(where: {status: {_eq: "check-out"}}) {
      aggregate {
        count
      }
    }
    usage_aggregate {
      aggregate {
        count
      }
    }
    images {
      __typename
      id
      name
      img
      createdOn
    }
    annotations {
      id
      name
      createdOn
      description
    }
  }
  zeus_jobboard_entry (order_by: {createdOn: desc}){
    __typename
    id
    name
    description
    customerPO
    serviceType
    status
    createdOn
  }
}`;


export const getAllRoutes = `
query getAllRoutes {
  zeus_route(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      node_type_id
      status
      createdOn
    }
  }
}`;


export const getRoutesForClient = `
query getRoutesForClient($client_id: uuid = "") {
  zeus_route(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      node_type_id
      status
      createdOn
    }
  }
}`;


export const getQuotesForClient = `
query getQuotesForClient($client_id: uuid = "") {
  zeus_quotation_view(where: {_and: {client_id: {_eq: $client_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const getSalesDocument = `
query getSalesDocument($id: uuid = "") {
  zeus_sales_document_by_pk(id: $id) {
    __typename
    id
    name
    amount
    funnel
    description
    status
    theDate
    createdOn
    modifiedOn
    closedOn
    acceptedOn
    preparedBy
    revision
    document_type
    jobBoardEntry{
      __typename
      id
      name
      companyName
      description
      createdOn    
      modifiedOn 
      status
    }    
    parent {
      __typename
      id
      name
      description
      createdOn    
      modifiedOn  
      status
      document_type
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    contact {
      __typename
      id
      name
      firstName
      lastName
      email
      createdOn
      modifiedOn
    }
    client {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }   
    invoices: sales_documents(where: {document_type: {_eq: "Invoice"}}, order_by:{createdOn:desc}) {
      __typename
      id
      name
      description
      amount
      createdOn
      document_type
      status
      theDate
      closedOn
      acceptedOn
      parent {
        __typename
        id
        name
        description
      }
    }
    purchaseOrders: sales_documents(where: {document_type: {_eq: "PurchaseOrder"}}, order_by:{createdOn:desc}) {
      __typename
      id
      name
      description
      amount
      createdOn
      document_type
      status
      theDate
      closedOn
      acceptedOn
    }  
    quotations: sales_documents(where: {document_type: {_eq: "Quotation"}}, order_by:{createdOn:desc}) {
      __typename
      id
      name
      description
      amount
      createdOn
      document_type
      status
      theDate
      closedOn
      acceptedOn
    }           
  }
}
`;


export const getQuotesForSupplier = `
query getQuotesForSupplier($supplier_id: uuid = "") {
  zeus_quotation_view(where: {_and: {supplier_id: {_eq: $supplier_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const getQuotesForContact = `
query getQuotesForContact($contact_id: uuid = "") {
  zeus_quotation_view(where: {_and: {contact_id: {_eq: $contact_id}, document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    amount
    document_type
    status
    createdOn
    acceptedOn
    closedOn
    days_to_accept
    days_to_close
    job_id
    parent_id
  }
}`;


export const getAllProductLines = `
query getAllProductLines {
  zeus_product_line(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    supplier {
      id
      name
      status
      craetedOn
    }
    product_line_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getProductLine = `
query getProductLine($id: uuid = "") {
  zeus_product_line_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    active_products: products(order_by: {name: asc}, where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      createdOn
      status
    }
    all_products: products(order_by: {name: asc}) {
      __typename
      id
      name
      createdOn
      status
    }    
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    products_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getMeasurementPoint = `
query getMeasurementPoint($id: uuid = "") {
  zeus_measurement_point_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    asset {
      __typename
      id
      name
      description
      status
      createdOn
    }
    measurements(order_by: {createdOn: desc}) {
      __typename
      id
      mAxis
      mType
      description
      createdOn
      status
      values
    }
  }
}
`;


export const getAllUsers = `
query getAllUsers {
  zeus_zeus_user(order_by: {firstName: asc}) {
    __typename
    id
    alias
    firstName
    lastName
    email
    description
    global_permissions
    status
    createdOn
    roles_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getUser = `
query getUser($id: uuid!) {
  zeus_zeus_user_by_pk(id: $id) {
    __typename
    id
    firstName
    lastName
    alias
    email
    description
    status
    createdOn
    roles {
      role {
        __typename
        id
        name
        description
        notifications(order_by: {createdOn: desc}, limit: 50) {
          __typename
          id
          name
          role
          description
          createdOn
        }
      }
    }  
    pinned_clients: clients {
      id
      createdOn
      client {
        id
        name
        alias
        description
      }
    }
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
    }       
    jobs_assigned(where: {status: {_eq: "active"}}) {
      __typename
      id
      name
      description
      quoteAmount
      companyName
      createdOn
      status
      purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate
      }        
      salesDocuments(order_by: {createdOn: desc}) {
        __typename
        id
        name
        document_type
        acceptedOn
        closedOn       
        preparedBy
        status
        contact {
          __typename
          id
          firstName
          lastName
          phone
          email
        }
      }      
    }
    tickets_created {
      __typename
      id
      name
      description
      resolution
      status
      createdOn
      closedOn
      closer {
        __typename
        id
        alias
      }
      tool {
        __typename
        id
        name
      }
      toolNode {
        __typename
        id
        name
      }   
    }
    tickets_assigned {
      __typename
      id
      name
      description
      resolution
      status
      createdOn
      closedOn
      closer {
        __typename
        id
        alias
      }
      tool {
        __typename
        id
        name
      }
      toolNode {
        __typename
        id
        name
      }   
    }
    quotations: sales_documents (where: {document_type: {_eq: "Quotation"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    pending_quotations_agg: sales_documents_aggregate(where: {_and: [
        {status: {_eq: "pending"}}, 
        {document_type: {_eq: "Quotation"}}
      ]}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }
    open_activities: activities(where: {status: {_eq: "pending"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }  
    tool_bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}){
      __typename
      id
      description
      theDate
      status
      createdOn
      job {
        __typename
        id
        description
        companyName
        createdBy
        name
        status
      }
      tool {
        __typename
        id
        name
        description
        lastCheckedIn
        lastCheckedOut
        status
        serialNumber
        createdOn
      }
      node {
        __typename
        id
        name
        description
        createdOn
        status
        node_type
      }
    } 
    tool_usage(order_by: {createdOn: desc}, limit: 10){
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
      tool {
        __typename
        id
        name
        description
        lastCheckedIn
        lastCheckedOut
        status
        serialNumber
        createdOn
      }
      node {
        __typename
        id
        name
        description
        createdOn
        status
        node_type
      }      
      user {
        __typename
        id
        alias
      }      
    } 
  }
}
`;


export const getUser_1 = `
query getUser($id: uuid = "") {
  zeus_zeus_user_by_pk(id: $id) {
    __typename
    id
    firstName
    lastName
    alias
    email
    description
    status
    createdOn
    quotations: sales_documents (where: {_and: {document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    pending_quotations_agg: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }
    open_activities: activities(where: {_and: {status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }    
  }
  my_checkedout_nodes: zeus_tool_node(distinct_on: id, where: {_and: {usage: {user_id: {_eq: $id}}, status: {_eq: "in-use"}}}) {
    __typename
    id
    name
    status
    lastCheckedOut
    lat
    lng
    node_type
    usage(order_by: {createdOn: desc}, limit: 1) {
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
    }
  }
  my_checkedout_nodes_agg: zeus_tool_node_aggregate(distinct_on: id, where: {_and: {usage: {user_id: {_eq: $id}}, status: {_eq: "in-use"}}}) {
    aggregate {
      count
    }
  }
  my_checkedout_tools: zeus_tool(distinct_on: id, where: {_and: {usage: {user_id: {_eq: $id}}, status: {_eq: "in-use"}}}) {
    __typename
    id
    name
    status
    lastCheckedOut
    lat
    lng
    usage(order_by: {createdOn: desc}, limit: 1) {
      __typename
      id
      createdOn
      description
      status
      job {
        __typename
        id
        name
        companyName
        description
        createdOn
        status
      }
    }
  }  
  my_checkedout_tools_agg: zeus_tool_aggregate(distinct_on: id, where: {_and: {usage: {user_id: {_eq: $id}}, status: {_eq: "in-use"}}}) {
    aggregate {
      count
    }
  }
  my_jobs_assigned: zeus_job_assigned_to_user(where: {user_id: {_eq: $id}}) {
    jobs(where: {status: {_neq: "completed"}}, order_by: {createdOn: asc}) {
      __typename
      id
      name
      companyName
      createdOn
      salesDocuments(order_by: {createdOn: desc}) {
        __typename
        id
        name
        document_type
        acceptedOn
        closedOn       
        preparedBy
        status
        contact {
          __typename
          id
          firstName
          lastName
          phone
          email
        }
      }
    }
  }
}`;


export const getSensorsForGateway = `
query getSensorsForGateway($gateway_id: uuid = ""){
  zeus_sensors(where: {gateway_id: {_eq: $gateway_id}}){
    __typename
    id
    name
    alias
    serialNumber
    description
    asset_id
    gateway_id
    measurement_point_id
    status
    createdOn
    nfcTag
    lastMeasurement
    asset {
      id
      name
      description
    }
  }
}
`;


export const getRolesForUser = `
query getRolesForUser($user_id: uuid = ""){
  zeus_user_to_role(where: {user_id: {_eq: $user_id}}){
    __typename
    id
    is_default
    createdOn
    status
    role {
      __typename
      id
      name
      description
      status
      createdOn
    }
  }
}
`;


export const getZeusUser = `
query getZeusUser($id: uuid = "") {
  zeus_zeus_user_by_pk(id: $id) {
    __typename
    id
    firstName
    lastName
    alias
    description
    email
    createdOn
    global_permissions
    modifiedOn    
    status
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }  
    roles {
      __typename
      id
      is_default
      createdOn
      status
      role {
        __typename
        id
        name
        description
        createdOn
        status
      }
    }
    annotations (order_by: {createdOn: desc}){
      __typename
      id
      name
      createdOn
      description
      user {
        __typename
        id
        alias
      }
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }    
  }
  zeus_zeus_role(order_by: {name: asc}){
    __typename
    id
    name
    description
    createdOn
    status
  }
}
`;



export const getJobsForTech = `
query getJobsForTech($user_id: uuid = ""){
  zeus_job_assigned_to_user(where: {user_id: {_eq: $user_id}}) {
    jobs(where: {status: {_neq: "completed"}}, order_by: {createdOn: asc}) {
      __typename
      id
      name
      companyName
      createdOn
      salesDocuments(order_by: {createdOn: desc}) {
        __typename
        id
        name
        document_type
        acceptedOn
        closedOn       
        preparedBy
        status
        contact {
          __typename
          id
          firstName
          lastName
          phone
          email
        }
      }
    }
  }
}
`;

export const getUserByEmail = `
query getUserByEmail($email: String!) {
  zeus_zeus_user(where: {email: {_eq: $email}}) {
    __typename
    id
    email
    firstName
    lastName
    alias
    description
    status
    createdOn
    roles(order_by: {is_default: desc}) {
      role {
        __typename
        id
        name
        description
        notifications(order_by: {createdOn: desc}, limit: 20) {
          __typename
          id
          name
          role
          description
          createdOn
        }
      }
    }      
    active_tools: tool_usage(where: {status: {_eq: "checked-out"}}, order_by: {createdOn: desc}) {
      id
      description
      status
      createdOn
      tool {
        id
        name
        description
      }
      job {
        id
        name
        description
        companyName
      } 
    }  
  }
}
`;

/*
    quotations(where: {_and: {document_type: {_eq: "Quotation"}}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    pending_quotations_agg: sales_documents_aggregate(where: {_and: {status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
      aggregate {
        count
        sum {
          amount
        }
        max {
          amount
        }
        min {
          amount
        }
      }
    }
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }
    open_activities: activities(where: {_and: {status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
    }  
*/


export const getAllSuppliers = `
query getAllSuppliers {
  zeus_supplier(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    product_lines_aggregate {
      aggregate {
        count
      }
    }
    products_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getSupplier = `
query getSupplier($id: uuid = "") {
  zeus_supplier_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    products(order_by: {name: asc}) {
      __typename
      id
      name
      price
      description
      status
      createdOn
    }
    products_aggregate {
      aggregate {
        count
      }
    }
    active_products: products_aggregate(where: {_and: {status: {_eq: "active"}}}) {
      aggregate {
        count
      }
    }
    product_lines(order_by: {name: asc}) {
      id
      name
      status
    }
    product_lines_aggregate {
      aggregate {
        count
      }
    }
    sales_documents(order_by: {createdOn: desc}) {
      id
      name
      amount
      description
      funnel
      document_type
      createdOn
      status
    }
    won_quotations: sales_documents_aggregate(where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}) {
      aggregate {
        count
        avg {
          amount
        }
        max {
          amount
        }
        sum {
          amount
        }
      }
    }
    lost_quotations: sales_documents_aggregate(where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "closed"}}}) {
      aggregate {
        count
        avg {
          amount
        }
        max {
          amount
        }
        sum {
          amount
        }
      }
    }
    pending_quotations: sales_documents_aggregate(where: {_and: {document_type: {_eq: "Quotation"}, status: {_eq: "pending"}}}) {
      aggregate {
        count
        avg {
          amount
        }
        max {
          amount
        }
        sum {
          amount
        }
      }
    }
  }
}`;


export const getProductsForSupplier = `
query getProductsForSupplier($supplier_id: uuid = "") {
  zeus_product(where: {_and: {supplier_id: {_eq: $supplier_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    price
    description
    status
    createdOn
  }
}`;


export const getProductsForProductLine = `
query getProductsForProductLine($product_line_id: uuid = "") {
  zeus_product(where: {_and: {product_line_id: {_eq: $product_line_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    price
    description
    status
    createdOn
  }
}`;


export const getDataForMeasurementPoint = `
  query getDataForMeasurementPoint($mpoint_id: uuid = "") {
  zeus_measurement(where: {_and: {measurement_point_id: {_eq: $mpoint_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    mAxis
    mType
    description
    values
    createdOn
    status
    measurement_point {
      __typename
      id
      name
      description
      createdOn
    }
    asset {
      __typename
      id
      name
      description
    }
  }
}`;


export const getMeasurementData = `
query getMeasurementData($id: uuid = "") {
  zeus_measurement_by_pk(id: $id) {
    __typename
    id
    mAxis
    mType
    description
    createdOn
    status
    s3path
    s3bucket
    values
    measurement_point {
      __typename
      id
      name
      description
      status
    }
    asset {
      __typename
      id
      name
      description
      status
    }
  }
}
`;


export const getMeasurementsForAsset = `
  query getMeasurementsForAsset($asset_id: uuid = "") {
    zeus_asset(where: {_and: {id: {_eq: $asset_id}}}) {
      __typename
      id
      name
      description
      createdOn
      status
      measurement_points {
        __typename
        id
        name
        description
        status
        createdOn
        measurements(order_by: {createdOn: desc}) {
          __typename
          id
          mAxis
          mType
          description
          createdOn
          status
          values
        }
      }
    }
  }
`;


export const getAllContacts = `
query getAllContacts {
  zeus_contact(order_by: {firstName: asc}) {
    __typename
    id
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getAllComponentTypes = `
query getAllComponentTypes {
  zeus_component_type (order_by: {name: asc}){
    id
    name
    description
    createdOn
    modifiedOn
  }
}
`;


export const getContact_old = `
query getContact($id: uuid = "") {
  zeus_contact_by_pk(id: $id) {
    __typename
    id
    firstName
    lastName
    email
    description
    status
    createdOn
  }
}`;


export const getContact = `
query getContact($id: uuid = "") {
  zeus_contact_by_pk(id: $id) {
    id
    name
    firstName
    lastName
    email
    description
    createdOn
    phone
    client {
      id
      name
    }
    annotations(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
    }
    activities(order_by: {createdOn: desc}) {
      id
      name
      description
      createdOn
      status
    }
  }
  accepted_quotations_aggregate: zeus_sales_document_aggregate(where: {_and: {contact_id: {_eq: $id}, status: {_eq: "accepted"}, document_type: {_eq: "Quotation"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  pending_quotations_aggregate: zeus_sales_document_aggregate(where: {_and: {contact_id: {_eq: $id}, status: {_eq: "pending"}, document_type: {_eq: "Quotation"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
  quotations: zeus_sales_document(where: {_and: {contact_id: {_eq: $id}, document_type: {_eq: "Quotation"}, status: {_eq: "pending"}}}, order_by: {createdOn: desc}) {
    id
    name
    description
    amount
    acceptedOn
    closedOn
    stage
    status
  }
  accepted_quotations: zeus_sales_document(where: {_and: {contact_id: {_eq: $id}, document_type: {_eq: "Quotation"}, status: {_eq: "accepted"}}}, order_by: {createdOn: desc}) {
    id
    name
    description
    amount
    acceptedOn
    closedOn
    stage
    status
  }
  lost_quotations: zeus_sales_document(where: {_and: {contact_id: {_eq: $id}, document_type: {_eq: "Quotation"}, status: {_eq: "closed"}}}, order_by: {createdOn: desc}) {
    id
    name
    description
    createdOn
    modifiedOn
    status
    amount
  }
  lost_quotations_aggregate: zeus_sales_document_aggregate(where: {_and: {contact_id: {_eq: $id}, status: {_eq: "closed"}, document_type: {_eq: "Quotation"}}}) {
    aggregate {
      count
      sum {
        amount
      }
      max {
        amount
      }
      min {
        amount
      }
    }
  }
}`;


export const getAllAssets = `
query getAllAssets {
  zeus_asset(order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAllAssetsForClient = `
query getAllAssetsForClient($client_id: uuid = "") {
  zeus_asset(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    currentCondition
    criticality
  }
}`;


export const getAsset = `
query getAsset($id: uuid = "") {
  zeus_asset_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    currentCondition
    criticality
    surveys(order_by: {theDate: desc}, limit: 12) {
      __typename
      id
      theDate
      status
      severity
      recommendations
      findings_from_work_completed
      createdOn
      summary
      component {
        __typename
        id
        name
      }
      technician {
        __typename
        id
        alias
      }
    }  
    components(order_by: {name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      locations {
        __typename
        id
        name
        description
        createdOn
        status
        sensors {
          __typename
          id
          name
          alias
          lastMeasurement
          rms
          createdOn
          status
        }
      }
    }       
    measurementPoints {
      __typename
      id
      name
      description
      status
      createdOn
    }
    sensors {
      __typename
      id
      name
      alias
      description
      createdOn
      modifiedOn
      status
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }    
  }
}`;


export const getComponent = `
query getComponent($id: uuid = "") {
  zeus_component_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
    asset {
      __typename
      id
      name
      description
    }
    locations(order_by:{name: asc}) {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
      sensors {
        __typename
        id
        name
        nfcTag
        description
        lastMeasurement
        status
        createdOn
        modifiedOn
        user_defined_fields
      }
    }
    sensors {
      __typename
      id
      name
      nfcTag
      description
      lastMeasurement
      status
      createdOn
      modifiedOn
      user_defined_fields
      asset {
        __typename
        id
        name
        description
      }
    }
    annotations(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    activities(order_by:{createdOn:desc}, limit: 20) {
      __typename
      id
      name
      description
      createdOn
    } 
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    } 
  }
}
`;


export const getLocationsForComponent = `
query getLocationsForComponent($component_id: uuid = "") {
  zeus_component_location(where: {parent_id: {_eq: $component_id}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    modifiedOn
    sensors {
      __typename
      id
      name
      nfcTag
      description
      lastMeasurement
      status
      createdOn
      modifiedOn
      user_defined_fields
    }
  }
}
`;


export const getComponentLocationsForAsset = `
query getComponentLocationsForAsset($asset_id: uuid = "") {
  zeus_component_location(where: {asset_id: {_eq: $asset_id}}, order_by: {component: {name: asc}}) {
    __typename
    id
    name
    description
    createdOn
    status
    component {
      __typename
      id
      name
      description
      status
      createdOn
      component_type {
        __typename
        id
        name
        description
        createdOn
      }
    }
    asset {
      __typename
      id
      name
      description
      createdOn
    }
  }
}
`;


export const getRoute = `
query getRoute($id: uuid = "") {
  zeus_route_by_pk(id: $id) {
    __typename
    id
    name
    description
    status
    createdOn
    client_node {
      __typename
      id
      name
      description
      node_type_id
      status
      createdOn
    }
    assets {
      __typename
      id
      name
      description
      status
      createdOn
    }
  }
}`;


export const getAllQuotations = `
query getAllQuotations {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}) {
    __typename
    id
    name
    funnel
    amount
    description
    acceptedOn
    createdOn
    closedOn
    status
    preparedBy
    revision
    client {
      __typename
      id
      name
      region: address(path: "region.province")
    }
  }
}`;


export const getQuotationsByMonthAndSupplier = `
query getQuotationsByMonthAndSupplier($created_year: float8 = "", $created_month: float8 = "", $supplier: String = "") {
  zeus_quotation_view(where: {_and: {created_year: {_eq: $created_year}, created_month: {_eq: $created_month}, supplier_code: {_eq: $supplier}}}) {
    id
    name
    amount
    funnel
    document_type
    description
    status
    supplier_code
    client_name
    region
    acceptedOn
    accepted_month
    accepted_quarter
    accepted_year
    client_id
    closedOn
    closed_month
    closed_quarter
    closed_year
    contact_id
    createdOn
    created_month
    created_quarter
    created_year
    days_to_accept
    days_to_close
    modifiedOn
    job_id
    parent_id
    supplier_id
    client
  }
}
`;

 
export const getQuotation = `
query getQuotation($id: uuid = "") {
  zeus_sales_document_by_pk(id: $id) {
    __typename
    id
    name
    amount
    funnel
    description
    status
    createdOn
    modifiedOn
    closedOn
    acceptedOn
    preparedBy
    revision
    parent {
      __typename
      id
      name
      description
      createdOn    
      modifiedOn  
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
    }
    contact {
      __typename
      id
      name
      firstName
      lastName
      email
      createdOn
      modifiedOn
    }
    client {
      __typename
      id
      name
      description
      status
      createdOn
      modifiedOn
    }
    attachments {
      __typename
      id
      name
      bucket
      path
      description
      createdOn
      modifiedOn
    }
    attachments_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getAllPurchaseOrders = `
query getAllPurchaseOrders {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
  }
}`;


export const getPurchaseOrder = `
query getPurchaseOrder($id: uuid = "") {
  zeus_sales_document(where: {_and: {document_type: {_eq: "PurchaseOrder"}, id: {_eq: $id}}}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getComponentsForAsset = `
query getComponentsForAsset($asset_id: uuid = "") {
  zeus_component(where: {asset_id: {_eq: $asset_id}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    status
    locations(order_by: {name: asc}) {
      __typename
      id
      name
      description
      createdOn
      status
      sensors {
        __typename
        id
        name
        alias
        description
        status
        nfcTag
        lastMeasurement
        user_defined_fields
        measurements(limit: 10) {
          __typename
          id
          apath
          xpath
          timestamp
          rms
          mAxis
          mType
          bucket
        }
      }      
    }
  }
}
`;

export const getSensor = `
query getSensor($id: uuid = "") {
  zeus_sensor_by_pk(id: $id) {
    __typename
    id
    name
    alias
    serialNumber
    description
    asset_id
    gateway_id
    measurement_point_id
    status
    createdOn
    nfcTag
    lastMeasurement
    component {
      __typename
      id
      name
      description
      status
      component_type {
        __typename
        id
        name
        description
      }
    }
    component_location {
      __typename
      id
      name
      description
      status
    }    
    activities(order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      user {
        __typename
        id
        alias
      }
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }   
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }  
    gateway {
      __typename
      id
      name
      alias
      description
    }
    asset {
      __typename
      id
      name
      description
      status
      client {
        __typename
        id
        name
        description
      }       
      components(order_by: {name: asc}) {
        __typename
        id
        name
        description
        status
      }
      component_locations {
        __typename
        id
        name
        description
        status
        component {
          __typename
          id
          name
          description
          status
          component_type {
            __typename
            id
            name
            description
          }
        }
      }
    }    
    client {
      __typename
      id
      name
      description
    }
    measurements_aggregate {
      aggregate {
        count
      }
    }
    measurements(order_by: [{timestamp: desc},{mAxis: asc}], limit: 100) {
      __typename
      id
      timestamp
      bucket
      mAxis
      mType
      status
      apath
      xpath
      rms
      createdOn
    }  
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }        
  }
}`;


export const getAllSensors = `
query getAllSensors {
  zeus_sensor {
    __typename
    id
    name
    alias
    serialNumber
    description
    lastMeasurement
    asset_id
    gateway_id
    measurement_point_id
    status
    createdOn
    gateway {
      __typename
      id
      name
      alias
      description
    }
    asset {
      __typename
      id
      name
      description
    }
    measurements_aggregate {
      aggregate {
        count
        max {
          timestamp
        }        
      }
    }
  }
}`;


export const getAllGateways = `
query getAllGateways {
  zeus_gateway {
    __typename
    id
    name
    alias
    description
    createdOn
    status
    client {
      __typename
      id
      name
      description
    }
    parent {
      __typename
      id
      name
      description
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }
  }
}`;


export const getGateway = `
query getGateway($id: uuid = "") {
  zeus_gateway_by_pk(id: $id) {
    __typename
    id
    name
    alias
    description
    createdOn
    status
    client {
      __typename
      id
      name
      description
      client_nodes(order_by: {name: asc}) {
        __typename
        id
        name
        description
        status
      }
    }
    parent {
      __typename
      id
      name
      description
      status
    }
    sensors(order_by: {name: asc}) {
      __typename
      id
      name
      alias
      description
      createdOn
      modifiedOn
      status
    }
    sensors_aggregate {
      aggregate {
        count
      }
    }
    annotations {
      __typename
      id
      name
      createdOn
      description
    }    
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    open_tickets: tickets(where: {status: {_eq: "open"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      status
      user {
        __typename
        id
        alias
      }
    }
    closed_tickets: tickets(where: {status: {_eq: "closed"}}, order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      createdOn
      modifiedOn
      closedOn
      status
      user {
        __typename
        id
        alias
      }
    }  
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }       
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }        
  }
}`;


export const getAllInvoices = `
query getAllInvoices {
  zeus_sales_document(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
    __typename
    id
    name
    amount
    description
    createdOn
    closedOn
    status
  }
}`;


export const getInvoice = `
query getInvoice($id: uuid = "") {
  zeus_sales_document(where: {_and: {document_type: {_eq: "Invoice"}, id: {_eq: $id}}}) {
    __typename
    id
    name
    description
    status
    createdOn
  }
}`;


export const getAllJobBoardEntries = `
query getAllJobBoardEntries {
  zeus_jobboard_entry(order_by: {jobCounter: desc}) {
    __typename
    id
    name
    jobCounter
    description
    customerPO
    serviceType
    status
    createdOn
    primacQuoteName
    client {
      __typename
      id
      name
      description
    }
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}, limit: 1) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
  }
}`;


export const getSurveysForAsset = `
query getSurveysForAsset($asset_id: uuid = "") {
  zeus_asset_survey(where: {asset_id: {_eq: $asset_id}}) {
    __typename
    id
    severity
    recommendations
    notification_num
    modifiedOn
    summary
    status
    theDate
    createdOn
    findings_from_work_completed
    asset {
      __typename
      id
      name
      description
      currentCondition
      createdOn
      status
    }
  }
}
`;


export const getJobBoardEntry = `
query getJobBoardEntry($id: uuid = "") {
  zeus_jobboard_entry_by_pk(id: $id) {
    __typename
    id
    name
    jobCounter
    description
    createdOn
    quoteAmount
    status
    scheduled
    poAmount
    invoiceNum
    invoiceDate
    invoiceAmount
    customerPO
    createdBy
    companyName
    completedOn
    primacQuoteName
    primacPO
    isProduct
    isService
    isTravelBooked
    serviceType
    technician {
      __typename
      id
      alias
    }    
    client {
      __typename
      id
      name
      description
    }
    contact {
      __typename
      id
      firstName
      lastName
      email
      description
    }    
    activities (order_by:{createdOn:desc}, limit: 20){
      __typename
      id
      name
      description
      createdOn
    }       
    quotations: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Quotation"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
    }
    purchaseOrders: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "PurchaseOrder"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
      theDate
      invoices: sales_documents(where: {document_type: {_eq: "Invoice"}}) {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate        
      }
    }
    invoices: salesDocuments(order_by: {createdOn: desc}, where: {document_type: {_eq: "Invoice"}}) {
      __typename
      id
      name
      description
      amount
      status
      createdOn
      closedOn
      theDate
      parent {
        __typename
        id
        name
        description
        amount
        status
        createdOn
        closedOn
        theDate        
      }
    }
    annotations {
      __typename
      id
      name
      description
      createdOn
      user {
        __typename
        id
        alias
      }
      attachments_aggregate {
        aggregate {
          count
        }
      }
      images_aggregate {
        aggregate {
          count
        }
      }
    }
    attachments {
      __typename
      id
      name
      description
      createdOn
      bucket
      path
      src
      url
      user {
        __typename
        id
        alias
      }
    }  
    images (order_by: {createdOn: desc}) {
      __typename
      id
      name
      description
      img
      src
      lat
      lng
      createdOn
      user {
        __typename
        id
        alias
      }  
    }
    tools_used(distinct_on: tool_id, where: {tool_id: {_is_null: false}}) {
      tool {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
      }
    }
    toolnodes_used(distinct_on: node_id, where: {node_id: {_is_null: false}}) {
      node {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
      }
    }   
    scheduled_dates(order_by: {theDate:asc}) {
      __typename
      id
      theDate
      description
      createdOn
      status
      span_id
      technician {
        __typename
        id
        alias
      }
      user {
        __typename
        id
        alias
      }
    } 
    tools_booked(distinct_on: tool_id, where: {tool_id: {_is_null: false}}) {
      tool {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
        serialNumber
        locker {
          __typename
          id
          name          
        }
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
          user {
            __typename
            id
            alias
          }
        }
      }
    }
    toolnodes_booked: tools_booked(distinct_on: node_id, where: {node_id: {_is_null: false}}) {
      node {
        __typename
        id
        name
        description
        status
        createdOn
        lastCheckedIn
        lastCheckedOut
        bookings(where: {theDate: {_gte: "now()"}}, order_by: {theDate: asc}) {
          __typename
          id
          theDate
          description
          createdOn
          status
          user {
            __typename
            id
            alias
          }
        }
      }
    }      
  }
}
`;


export const getScheduleForJob = `
query getScheduleForJob ($job_id: uuid = ""){
  zeus_jobboard_schedule(where: {job_id: $job_id}) {
    __typename
    id
    theDate
    description
    status
    technician {
      __typename
      id
      alias
    }
    user {
      __typename
      id
      alias
    }
  }
}`;
  

export const getAllActivities = `
query getAllActivities {
  zeus_activity(order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    contact {
      __typename
      id
      firstName
      lastName
    }
    client {
      __typename
      id
      name
    }
  }
}
`;

export const getAllConditions = `
query getAllConditions {
  zeus_condition_type(order_by: {name: asc}){
    __typename
    id
    name
    description
    createdOn
    modifiedOn
    descriptors (order_by: {name: asc}){
      __typename
      id
      name
      description
      parent_id
      createdOn
      modifiedOn
    }
  }
}
`;


export const getAllConditionTypes = `
query getAllConditionsTypes {
  zeus_condition_type(order_by: {name: asc}){
    __typename
    id
    name
    description
    createdOn
    modifiedOn
  }
}
`;

export const getAllConditionDescriptorTypes = `
query getAllConditionDescriptorTypes {
  zeus_condition_descriptor_type(order_by: {name: asc}){
    __typename
    id
    name
    description
    createdOn
    modifiedOn
  }
}
`;


export const getAllLocations = `
query getAllLocations {
  zeus_location_type(order_by: {name: asc}){
    __typename
    id
    name
    description
    createdOn
    modifiedOn
  }
}
`;


export const getDescriptorsForCondition = `
query getDescriptosrForCondition($condition_id: uuid = "") {
  zeus_condition_descriptor_type(where: {condition_id: {_eq: $condition_id}}, order_by: {name: asc}){
    __typename
    id
    name
    description
    parent_id
  }
}
`;


export const getActivitiesForClient = `
query getActivitiesForClient($client_id: uuid = "") {
  zeus_activity(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    salesDocument {
      __typename
      id
      name
      document_type
    }
    client {
      __typename
      id
      name
    }
    contact {
      __typename
      id
      lastName
      firstName
    }
  }
}
`;


export const getActivitiesForContact = `
query getActivitiesForContact($contact_id: uuid = "") {
  zeus_activity(where: {_and: {contact_id: {_eq: $contact_id}}}, order_by: {createdOn: desc}) {
    __typename
    id
    name
    description
    createdOn
    closedOn
    status
    salesDocument {
      __typename
      id
      name
      document_type
    }
    client {
      __typename
      id
      name
    }
    contact {
      __typename
      id
      lastName
      firstName
    }
  }
}
`;

export const getClientNode = `
query getClientNode($id: uuid = "") {
  zeus_client_node_by_pk(id: $id) {
    __typename
    id
    name
    description
    createdOn
    status
    assets {
      __typename
      id
      name
      description
      status
    }
    node_type {
      __typename
      id
      name
    }
    parent_node {
      __typename
      id
      name
    }
    client {
      __typename
      id
      name
    }
    children {
      __typename
      id
      name
    }
    routes {
      __typename
      id
      name
      description
    }
  }
}
`;

export const getClientNodesForClient = `
query getClientNodesForClient($client_id: uuid = "") {
  zeus_client_node(where: {_and: {client_id: {_eq: $client_id}}}, order_by: {name: asc}) {
    __typename
    id
    name
    description
    status
    createdOn
    node_type {
      __typename
      id
      name
      status
    }  
    parent_node {
      __typename
      id
      name
      status
    }
    routes_aggregate {
      aggregate {
        count
      }
    }
    children_aggregate {
      aggregate {
        count
      }
    }
    assets_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;
