import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { MemoryRouter as Router } from 'react-router';
import { Link as RouterLink, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ZTitle from 'components/core/ZTitle';

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getSensor,  getQuotesForClient } from 'queries_urql';
import { addClient, updateSensor } from 'mutations_urql';
import { subscribeAllActiveJobBoardEntries, subscribeSensor } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Moment from 'react-moment';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ZWizard from 'components/core/ZWizard';

import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZSensorBox from 'components/boxes/ZSensorBox';
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';

import ZFluentCalendar from 'components/core/ZFluentCalendar';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';// ZAnnotationsList
import ZAttachmentsList from 'components/lists/ZAttachmentsList';// ZAttachmentsList
import ZTicketsList from 'components/lists/ZTicketsList';//ZTicketsList
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog';
import ZAddTicketDialog from 'components/dialogs/ZAddTicketDialog';
import ZImageListHorizontal from 'components/lists/ZImageListHorizontal';
import ZMeasurementList from 'components/lists/ZMeasurementList';
import ZMeasurementPlot from 'components/charts/ZMeasurementPlot'; 
import ZMeasurementPlotly from 'components/charts/ZMeasurementPlotly';
import ZSensorRMSPlotly from 'components/charts/ZSensorRMSPlotly'; //ZSensorRMSPlotly

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import { downloadObjectFromAzure } from 'azure/AzureBlobAPI';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import ReactTooltip from 'react-tooltip';
import eventBus from 'utils/EventBus';

//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 400,
    height: 800,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

//////////////////////////////////////////////

const ZSensorView = (props) => {
  const theme = useTheme();
  console.log('ZSensorView->props');
  console.log(props);

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });

  const classes = useStyles();
  let { id } = useParams();
  const [userData, setUserData] = useLocalStorage('userData');

  const [state, setState] = React.useState({
    selectedData: undefined,
    showAnnotationDialog: false,
    showTicketDialog: false,
  });
  
  const [tabIndex, setTabIndex] = React.useState(0);

  let row = undefined;
  let measurementMap = {};
  let selectedData = [];

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false});
  }  

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };


  const handleSelectionModelSelect = async (selectionModel) => {
    console.log('handleSelectionModelSelect')
    console.log(selectionModel)
    let rawData = []
    for(var i=0; i < selectionModel.length; i++){
      var mm = selectionModel[i];
      var measurement = measurementMap[mm];
      console.log(`retrieving data from azure for: ${mm} - ${measurement.apath} - ${measurement.timestamp}`)
      console.log(measurement);

      let data = await downloadObjectFromAzure(measurement.apath);
      let values = [[],[]];
      if(measurement.mAxis === 'x'){
        values[1] = data.data[0].slice(0)
        values[0] = data.data[0].map((x,index)=>{
          return index
        })
      }
      if(measurement.mAxis === 'y'){
        values[1] = data.data[1].slice(0)
        values[0] = data.data[1].map((x,index)=>{
          return index
        })     
      }
      if(measurement.mAxis === 'z'){
        values[1] = data.data[2].slice(0)
        values[0] = data.data[2].map((x,index)=>{
          return index
        })      
      }
      measurement.values = values;
      console.log(values.slice(0,5))
      rawData.push(measurement)
    }

    setState({...state, selectedData: rawData})
  }


  const handleSelectionModelSelect_1 = async (selectionModel) => {
    console.log('handleSelectionModelSelect')
    console.log(selectionModel)
    let rawData = []
    for(var i=0; i < selectionModel.length; i++){
      var mm = selectionModel[i];
      var measurement = measurementMap[mm];
      console.log(`retrieving data from azure for: ${mm} - ${measurement.apath} - ${measurement.timestamp}`)
      console.log(measurement);

      let data = await downloadObjectFromAzure(measurement.apath);
      let values = [];
      if(measurement.mAxis === 'x'){
        //values = {"name":"displacement", "value": data.data[0]}
        values = data.data[0].map((x,index)=>{
          return {"time": index, "value": x}
        })
      }
      if(measurement.mAxis === 'y'){
        //values = {"name":"displacement", "value": data.data[1]}
        values = data.data[1].map((y,index)=>{
          return {"time": index, "value": y}
        })        
      }
      if(measurement.mAxis === 'z'){
        //values = {"name":"displacement", "value": data.data[2]}
        values = data.data[2].map((z,index)=>{
          return {"time": index, "value": z} 
        })        
      }
      measurement.values = values;
      console.log(values.slice(0,5))
      rawData.push(measurement)
    }

    setState({...state, selectedData: rawData})
  }


  const [updateSensorResult, updateFunction] = useMutation(updateSensor);

  /////////////////
  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = response.zeus_tool_by_pk
    return response
    //jobs = data.zeus_jobboard_entry
  };
 
  console.log('setting up subscription')

  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeSensor,
    variables: { id },
    pause: !window.navigator.onLine,
    //pause: state.showBookingDialog,
  }, handleSubscription); 

  const [ queryResult, reexecuteQuery ]  = useQuery({
    query: getSensor,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  let { data, fetching, error } = subResult

  if(!window.navigator.onLine){
    console.log('not online')
    data = queryResult.data;
    fetching = queryResult.fetching;
    error = queryResult.error;
  }  

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  console.log('ZSensorView->data');
  console.log(data);

  row = data.zeus_sensor_by_pk  
  for(var i=0; i < row.measurements.length; i++){
    var mm = row.measurements[i];
    measurementMap[mm.id] = mm;
  }

  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{setState({...state, showAnnotationDialog: true});}}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  
  
  let addTicketAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showTicketDialog: true});
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )   

  let loadDataAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={(event,selectedIDs)=>{
          console.log('ZSensorView->selectedIDs')
          console.log(selectedIDs)
        }}
      >
        <CloudDownloadIcon />
      </IconButton>      
    </div>    
  )

  let toprow = (
    <Row>
      <Col xs={6}>
        <ZSensorBox id='sensor_box' 
          sensor={row}
          name={row.name} 
          description={row.description} 
          annotations={row.annotations}
          refresh={executeSubscription}
          fullBox
          showImages
          static
        />
        <Row><br/></Row>
        <ZSensorRMSPlotly sensor={row} width={700} limit={10} realtime/>   
      </Col>
      <Col xs={6}>     
       { row.activities ?
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="jobboard_entry">
                  <TimelineIcon />
                </Avatar>
              }
              title='Timeline'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZActivitiesTimeline size={5} items={row.activities} />
            </Paper>  
          </Card> 
          : <div/>
        }        
        <Row><br/></Row>
        <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`sensor/${row.id}/attachments`} multiple static/>        
        <Row><br/></Row>
        <ZMeasurementList key='measurement_panel' sensor={row} onSelectionModelSelect={handleSelectionModelSelect}  data={row.measurements} pageSize={10}  static/>
      </Col>     
    </Row>    
  )

  //////////////////////////
  console.log("selectedData")
  console.log(state.selectedData)

  return (
    <div>
      <Container fluid>
        {toprow}
        <Row><br/></Row>
        {/* this is where the plotting port goes v */}
        <Row>
          <Col xs={12}>
            <ZSensorRMSPlotly sensor={row} realtime/>      
          </Col>
        </Row>         
        <Row><br/></Row>        
        <Row>
          <Col xs={12}>
            <ZMeasurementPlotly title={row.name} data={state.selectedData ? state.selectedData[0].values : [[],[]]} />      
          </Col>
        </Row>         
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
            <ZAddTicketDialog open={state.showTicketDialog} parent={row} handleClose={()=>setState({...state, showTicketDialog: false})} />
            <Paper>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab label="Open Tickets" />
                  <Tab label="Closed Tickets" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.asset} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>              
            </Paper>
          </Col>
        </Row> 
      </Container>
    </div>
  )
}

export default ZSensorView;


//{/* <ZAttachmentsList key='docs_panel' action={<div></div>} parent={row} data={row.attachments} path={`sensor/${row.id}/attachments`} multiple static/> */}

/*

              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={280} descriptionW={450} client={row} parent={row.locker} action={addNoteAction} items={row.annotations} static/>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Open Tickets" action={addTicketAction} data={row.open_tickets} static/>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>                   
                    <ZTicketsList key='tickets_panel' title="Closed Tickets" action={<div></div>} data={row.closed_tickets} static/>
                  </Box>
                )}                
              </Box>


      <Col xs={6}>     
        <ZMeasurementList key='measurement_panel' sensor={row} data={row.measurements}  static/>
      </Col>

        <Row><br/></Row>
          <Card variant="outlined">
            <CardHeader
              avatar={
                <Avatar aria-label="sensor_images" className={classes.image_avatar}>
                  <PhotoLibraryIcon />
                </Avatar>
              }
              title='Images'
              titleTypographyProps={{variant:'h5'}}
            />
            <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
              <ZImageListHorizontal key='image_list' parent_id={row.id} path={`sensor/${row.id}/images`} data={row.images} /> 
            </Paper>  
          </Card> 


*/

