
import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import Plot from 'react-plotly.js';
import ZTitle from 'components/core/ZTitle';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';

/*
import { useQuery, useMutation } from '@apollo/client';
import { monthlySalesQuery, monthlySupplierSalesQuery } from 'queries';
import { addQuotation, updateSalesDocument } from 'mutations';
*/

import { useQuery, useMutation } from 'urql';
import { monthlySalesQuery, monthlySupplierSalesQuery } from 'queries_urql';
import { addQuotation, updateSalesDocument  } from 'mutations_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//////////////////////////
/*
props {
  width: number
  height: number
  year: number
  funnel: string
  percentages: boolean
}
*/
//////////////////////////

const ZMeasurementPlotly = (props) => {
  const theme = useTheme();
  
  ////////////////////////////////////

  return (
    <Card>
      <ZTitle>{props.title ? props.title : "Raw Data"}</ZTitle>
      <Paper>
        <Plot
          data={[
            {
              x: props.data[0],
              y: props.data[1],
              type: 'scatter',
              mode: 'lines+markers',
              marker: {color: 'blue'},
            },
          ]}
          layout={ {width: props.width ?? 1200, height: props.height ?? 400, title: props.title ? props.title : "Raw Data"} }
        />
      </Paper>
    </Card>  
  );
}


export default ZMeasurementPlotly;

/*
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
*/















/*

            <Card>
              <ZTitle>Raw Data</ZTitle>
              <Paper>
                <LineChart data={state.selectedData ? state.selectedData[0].values : []} width={props.width ? props.width: 1200} height={props.height ? props.height : 400}
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                  }}
                >
                  <XAxis
                    dataKey="time"
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                    //tick={renderCustomAxisTick}
                  >
                    <Label
                      position="middle"
                      style={{
                        //textAnchor: 'middle',
                        fill: theme.palette.text.primary,
                        ...theme.typography.body1,
                      }}
                    >
                    </Label>
                  </XAxis>
                  <YAxis
                    //dataKey="value"
                    stroke={theme.palette.text.secondary}
                    style={theme.typography.body2}
                  >
                    <Label
                      angle={270}
                      position="left"
                      style={{
                        textAnchor: 'middle',
                        fill: theme.palette.text.primary,
                        ...theme.typography.body1,
                      }}
                    >
                      Displacement
                    </Label>
                  </YAxis>
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  
                  <Line
                    isAnimationActive={false}
                    type="monotone"
                    dataKey="value"
                    stroke={theme.palette.primary.main}
                    dot={false}
                  />
                  
                  <Tooltip/>
                </LineChart>
              </Paper>
            </Card>  

*/
