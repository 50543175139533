import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";

import { useLocalStorage } from 'hooks/useStorage';

//import { useQuery, useMutation } from '@apollo/client';
//import { getClient,  getQuotesForClient } from 'queries';
//import { addClient, updateClient } from 'mutations';

import { useQuery, useMutation, useSubscription } from 'urql';
import { getClient,  getQuotesForClient } from 'queries_urql';
import { addClient, updateClient } from 'mutations_urql';
import { subscribeClient } from 'subscriptions_urql';
import { usePollingQuery } from 'hooks/urql/usePollingQuery';

//import GridLayout from 'react-grid-layout';
//import { Responsive, WidthProvider } from 'react-grid-layout';

import Typography from '@material-ui/core/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import TimelineIcon from '@material-ui/icons/Timeline';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';


import ZWizard from 'components/core/ZWizard';
import ZSensorDetailPane from 'components/core/ZSensorDetailPane';

import ZAddClientDialog from 'components/dialogs/ZAddClientDialog';
import ZClientBox from 'components/boxes/ZClientBox';
import ZContactsList from 'components/lists/ZContactsList';
import ZQuotationsList from 'components/lists/ZQuotationsList';  //ZQuotationsList
import ZRoutesList from 'components/lists/ZRoutesList'; // ZRoutesList
import ZInvoicesList from 'components/lists/ZInvoicesList';
import ZAnnotationsList from 'components/lists/ZAnnotationsList';
import ZActivitiesList from 'components/lists/ZActivitiesList';
import ZClientNodesList from 'components/lists/ZClientNodesList'; //ZClientNodesList
import ZGatewaysList from 'components/lists/ZGatewaysList';
import ZAssetsList from 'components/lists/ZAssetsList';//ZAssetsList
import ZActivitiesTimeline from 'components/core/ZActivitiesTimeline';
import ZAttachmentsList from 'components/lists/ZAttachmentsList';
import ZAssetSurveyList from 'components/lists/ZAssetSurveyList';//ZAssetSurveyList
import ZPurchaseOrdersList from 'components/lists/ZPurchaseOrdersList';
import ZComponentsList from 'components/lists/ZComponentsList';
import ZSensorsList from 'components/lists/ZSensorsList';
import ZJobsList from 'components/lists/ZJobsList';

import ZAddJobPurchaseOrderDialog from 'components/dialogs/ZAddJobPurchaseOrderDialog';
import ZAddAnnotationDialog from 'components/dialogs/ZAddAnnotationDialog'; //ZAddSensorDialog
import ZAddSensorWizard from 'components/dialogs/ZAddSensorWizard';
import ZAddAssetDialog from 'components/dialogs/ZAddAssetDialog';

import ReactTooltip from 'react-tooltip';

import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import {
  Container as ReactContainer,
  Row as ReactRow,
  Col as ReactCol
} from 'react-grid';

import eventBus from 'utils/EventBus';


//////////////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  scrollablePaper: {
    overflowY: 'scroll',
    maxHeight: 400,
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

//////////////////////////////////////////////

const ZClientView = (props) => {
  const classes = useStyles();
  const history = useHistory();

  let path_element = props.location.pathname.split('/')[1]
  console.log(path_element)
  eventBus.dispatch("pageLoad", { message: path_element });
    
  let { id } = useParams();

  const [userRoles, setUserRoles] = useLocalStorage('userRoles');
  const [state, setState] = React.useState({
    tabIndex: 0,
    tabIndexB: 0, 
    showDetailPane: false,
    activeEntry: null,
    showAssetDialog: false,
    showSensorDialog: false,
    showAnnotationDialog: false,
    showClientNodeDialog: false,
    showPODialog: false,
  });

  //const [tabIndex,  setTabIndex] = React.useState(0); //tabIndexB
  //const [tabIndexB, setTabIndexB] = React.useState(0);

  let row = {}
  let components = []
  let component_locations = []

  const handleTabChange = (event, newTabIndex) => {
    //setTabIndex(newTabIndex);
    setState({...state, tabIndex: newTabIndex})
  };

  const handleAdminTabChange = (event, newTabIndexB) => {
    //setTabIndexB(newTabIndexB);
    setState({...state, tabIndexB: newTabIndexB})
  };

  const handleCloseAnnotation = () => {
    setState({...state, showAnnotationDialog: false})
  }

  const handleCloseAsset = () => {
    setState({...state, showAssetDialog: false})
  }

  const handleCloseSensor = () => {
    setState({...state, showSensorDialog: false})
  }

  const handleCloseClientNode = () => {
    setState({...state, showClientNodeDialog: false})
  }

  /////////////////

  const [updateClientResult, updateFunction] = useMutation(updateClient);

  const handleSubscription = (previousData, response) => {
    console.log('handleSubscription::response')    
    console.log('previousData')
    console.log(previousData)
    console.log('response')
    console.log(response)
    //row = response
    //row = data.zeus_client_by_pk
    //jobs = data.zeus_jobboard_entry
    return response;
  };
 
  console.log('setting up subscription')
  const [subResult, executeSubscription] = useSubscription({ 
    query: subscribeClient,
    variables: { id },
    pause: !window.navigator.onLine,
  }, handleSubscription); 

  const [ result, reexecuteQuery ] = useQuery({
    query: getClient,
    variables: { id },
    //pause: window.navigator.onLine,
  });

  console.log('result');
  console.log(subResult)

  let { data, fetching, error } = subResult;

  if(!window.navigator.onLine){
    data = result.data;
    fetching = result.fetching;
    error = result.error;
  }

  if (fetching) return (<h2>Loading... <CircularProgress /></h2>);
  if (error) return `Error! ${error.message}`;

  row = data.zeus_client_by_pk

  console.log('client->row')
  console.log(row)

  row.assets.forEach((asset)=>{
    asset.component_locations.forEach((loc)=>{
      var comp = loc.component;
      components.push(comp)
      component_locations.push(loc)
    })
  })

  /////////////

  let addNoteAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAnnotationDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addSensorAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          console.log('addSensorAction->clicked')
          setState({...state, showSensorDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addClientNodeAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showClientNodeDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )  

  let addPOAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showPODialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton>      
    </div>
  )

  let addAssetAction = (
    <div>
      <IconButton
        variant="contained"
        color="primary"
        onClick={()=>{
          setState({...state, showAssetDialog: true})
        }}
      >
        <AddCircleIcon />
      </IconButton> 
      <IconButton
        variant="contained"
        color="primary"
        data-for="client_view_tooltip"
        data-tip="upload asset spreadsheet"
        onClick={()=>{
          setState({...state, showAssetDialog: true})
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>      
    </div>    
  )

  let activeJobCount = 0;
  for(var job of row.jobs){
    if(job.status === 'active'){
      activeJobCount++;
    }
  }

  let activeJobsBadge = (<Badge badgeContent={activeJobCount} color="primary"/>)
  let sensorCountBadge = (<Badge badgeContent={row.sensors.length} color="primary"/>)
  let pairedSensorCountBadge = (<Badge badgeContent={row.paired_sensors.length} color="primary"/>)
  let gatewayCountBadge = (<Badge badgeContent={row.gateways.length} color="primary"/>)
  
  /////////////

  const styles = {
    breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
    containerMaxWidths: { sm: 540, md: 720, lg: 960, xl: 1140 },
    columns: 12,
    gutterWidth: 10
  };

  const Container = props => <ReactContainer {...props} styles={styles} />;
  const Row = props => <ReactRow {...props} styles={styles} />;
  const Col = props => <ReactCol {...props} styles={styles} />;

  var gateWayCol = {
    index: 3,
    field: 'gateway',
    title: 'Gateway',
    headerName: 'Gateway',
    minWidth: 220,
    editable: false,
    hide: false,
    renderCell: (params)=>{
      if(params.row.gateway){
        return (
          <div>
            <strong>
              <IconButton 
                component="span"
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 8 }}
                onClick={(event) => { 
                  let path = generatePath('/gateway/:id', {
                    id: params.row.gateway.id}
                    )
                  history.push(path)
                }}     
              >
                {params.row.gateway.alias ? params.row.gateway.alias : params.row.gateway.name}
              </IconButton>
            </strong>
          </div>
        )
      } else {
        return (<div>N/A</div>)
      }
    },
    valueGetter: (params) => {
      return params.row.gateway.alias ? params.row.gateway.alias : params.row.gateway.name
    },
  }

  var detailPaneCol = { 
    index: 0,
    field: 'id', 
    title: 'Action', 
    headerName: 'Action',
    width: 100,
    editable: 'never',
    renderCell: (params) => (
      <strong>
        <Router>
          <div>
          <IconButton 
              component="span"
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let entry = params.row;
                console.log("selected entry");
                console.log(entry);
                setState({...state,
                  activeEntry: entry,
                  showDetailPane: true,
                });
              }}          
            >
              <BurstModeIcon />
            </IconButton>                
            <IconButton 
              component="span"
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                let path = generatePath('/sensor/:id', {
                  id: params.row.id}
                  )
                history.push(path)
              }}          
            >
              <OpenInNewIcon />
            </IconButton>           
          </div>
        </Router>
      </strong>
    ),
  }
  
  ////////////////

  return (
    <div>
      <Container fluid>
        <ZAddAssetDialog open={state.showAssetDialog} client={row} assets={row.assets} onClose={()=>setState({...state, showAssetDialog: false})} />
        <ZAddJobPurchaseOrderDialog open={state.showPODialog} parent={row} job={data} onClose={()=>setState({...state, showPODialog: false})} />
        <ZAddAnnotationDialog open={state.showAnnotationDialog} parent_id={row.id} handleClose={handleCloseAnnotation} />
        <ZAddSensorWizard open={state.showSensorDialog} client={row} assets={row.assets} onClose={()=>setState({...state, showSensorDialog: false})} static/>
        <ZSensorDetailPane isOpen={state.showDetailPane} sensor={state.activeEntry} handleClose={()=>setState({...state, showDetailPane: false})} static/>
        <Row>
          <Col xs={6}>
            <ZClientBox id='client_box' 
              title="Client"
              client={row}
              name={row.name} 
              description={row.description} 
              createdOn={row.createdOn}
              surveyStats
              riskCriticalityPlot
              fullBox
            />
            <Row><br/></Row>
            <ZAttachmentsList key='docs_panel' parent={row} action={<div></div>} data={row.attachments} path={`client/${row.id}/attachments`} multiple static/>            
          </Col>
          <Col xs={6}>
          { row.activities ?
            <Card variant="outlined">
              <CardHeader
                avatar={
                  <Avatar aria-label="jobboard_entry">
                    <TimelineIcon />
                  </Avatar>
                }
                title='Timeline'
                titleTypographyProps={{variant:'h5'}}
              />
              <Paper className={classes.scrollablePaper} elevation={3} variant="outlined">
                <ZActivitiesTimeline items={row.activities} />
              </Paper>  
            </Card> 
            : <div/>
          }  
          <Row><br/></Row>          
            <ZContactsList key='contacts_panel' client={row} data={row.all_contacts} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <Paper>
              <Box>
                <Tabs value={state.tabIndex} onChange={handleTabChange}>
                  <Tab label="Notes" />
                  <Tab icon={activeJobsBadge} label="Jobs" />
                  <Tab icon={gatewayCountBadge} label="Gateways" />
                  <Tab icon={sensorCountBadge} label="Sensors" />
                  <Tab icon={pairedSensorCountBadge} label="Paired Sensors" />
                  <Tab label="Client Nodes" />
                  <Tab label="Routes" />
                  <Tab label="Assets" />
                  <Tab label="Components" />
                  <Tab label="Asset Surveys" />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {state.tabIndex === 0 && (
                  <Box>
                    <ZAnnotationsList key='notes_panel' nameW={220} descriptionW={350} action={addNoteAction} parent={row} items={row.annotations} static/>
                  </Box>             
                )} 
                {state.tabIndex === 1 && (
                  <Box>
                    <ZJobsList key='jobs_panel' title='Client Jobs' nameW={250} descriptionW={450} statusW={200} client={row} parent={row} data={row.jobs} static/>
                  </Box>
                )}                             
                {state.tabIndex === 2 && (
                  <Box>
                    <ZGatewaysList key='gateways_panel' title='Gateways' nameW={240} aliasW={280} descriptionW={380} statusW={200} client={row} items={row.gateways} static />
                  </Box>
                )}
                {state.tabIndex === 3 && (
                  <Box>
                    <ZSensorsList key='sensors_panel' title="Sensors" nameW={200} aliasW={280} descriptionW={450} action={addSensorAction} client={row} items={row.sensors} static/>
                  </Box>     
                )}  
                {state.tabIndex === 4 && (
                  <Box>
                    <ZSensorsList key='paired_sensors_panel' title="Paired Sensors" nameW={200} aliasW={280} descriptionW={450} action={<div></div>} client={row} columns={gateWayCol} items={row.paired_sensors} static/>
                  </Box>     
                )}  
                {state.tabIndex === 5 && (
                  <Box>
                    <ZClientNodesList key='plants_panel' title='Client Nodes' nameW={220} descriptionW={380} statusW={200} client={row} items={row.client_nodes} static />
                  </Box>
                )}
                {state.tabIndex === 6 && (
                  <Box>
                    <ZRoutesList key='routes_panel' action={<div></div>} client={row} items={row.routes} static />
                  </Box>
                )}
                {state.tabIndex === 7 && (
                  <Box>
                    <ZAssetsList key='assets_panel' action={addAssetAction} client={row} items={row.assets} showStatus static />
                  </Box>
                )}
                {state.tabIndex === 8 && (
                  <Box>
                    <ZComponentsList key='components_panel' title='Components' nameW={280} descriptionW={450} rtclient={row} items={components} showStatus static />
                  </Box>
                )}                
                {state.tabIndex === 9 && (
                  <Box>
                    <ZAssetSurveyList key='survey_panel' action={<div></div>} client={row} items={row.asset_surveys} static />
                  </Box>
                )}
              </Box>
            </Paper>
            <Row><br/></Row>
            { userRoles.includes('admin') &&
              <Paper>
                <Box>
                  <Tabs value={state.tabIndexB} onChange={handleAdminTabChange}>
                    <Tab label="Quotations" />
                    <Tab label="Purchase Orders" />
                    <Tab label="Invoices" />
                  </Tabs>
                </Box>
                <Box sx={{ padding: 2 }}>
                  {state.tabIndexB === 0 && (
                    <Box>
                      <ZQuotationsList key='quotations_panel' action={<div></div>} client={row} items={row.quotations} descriptionW={350} static />
                    </Box>
                  )}
                  {state.tabIndexB === 1 && (
                    <Box>
                      <ZPurchaseOrdersList key='purchase_orders_panel' action={addPOAction} items={row.purchaseOrders} descriptionW={300} static />
                    </Box>
                  )}
                  {state.tabIndexB === 2 && (
                    <Box>
                      <ZInvoicesList key='invoices_panel' action={<div></div>} items={row.invoices} static />
                    </Box>
                  )}
                </Box>
              </Paper>
            }
          </Col>        
        </Row>           
      </Container>
      <ReactTooltip id="client_view_tooltip"/>
    </div>
  )
}

export default ZClientView;

/*
  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={8}>
            <ZClientBox id='client_box' 
              client={row}
              name={row.name} 
              description={row.description} 
              createdOn={row.createdOn}
              //annotations={row.annotations}
              //fullBox
            />
          </Col>
          <Col xs={4}>
            <ZContactsList key='contacts_panel' client={row} data={row.all_contacts} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={8}>
            <ZQuotationsList key='quotations_panel' client={row} items={row.quotations} static />
          </Col>
          <Col xs={4}>
            <ZActivitiesList key='activities_panel' client={row} items={row.activities} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={6}>
            <ZClientNodesList key='plants_panel' client={row} items={row.client_nodes} static />
          </Col>
          <Col xs={6}>
            <ZRoutesList key='routes_panel' client={row} items={row.routes} static />
          </Col>
        </Row>
        <Row><br/></Row>
        <Row>
          <Col xs={12}>
            <ZAssetsList key='routes_panel' client={row} items={row.assets} static />
          </Col>
        </Row>
      </Container>
    </div>
  )
*/

/*
<GridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200}>

<ZClientBox id='client_box' name="Primac" description="test description"/>

      <ResponsiveGridLayout className="layout" layouts={layouts}
        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
        cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}>
      </ResponsiveGridLayout>

      <ZAddClientDialog 
      open={showDialog} 
      handleClose={() => {
        setShowDialog(false);
      }}/>
*/
