import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { red, green, cyan, purple, lightBlue } from '@material-ui/core/colors';

import { MemoryRouter as Router, generatePath } from 'react-router';
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useLocalStorage } from '../../hooks/useStorage';
/*
import { useQuery, useMutation } from '@apollo/client';
import { getRoutesForClient } from 'queries';
import { addRoute, updateRoute } from 'mutations';
*/
import { useQuery, useMutation, useSubscription } from 'urql';
import { getRolesForUser } from 'queries_urql';
import { updateUserRole, deleteUserRole } from 'mutations_urql';

import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu'; //Menu
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BallotIcon from '@material-ui/icons/Ballot'; //BallotIcon
import ClearIcon from '@material-ui/icons/Clear';
import PolicyIcon from '@material-ui/icons/Policy';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'; //AddCircleOutlineIcon
import IconButton from '@material-ui/core/IconButton';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import ButtonGroup from '@material-ui/core/ButtonGroup'; //ButtonGroup
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab'; //Fab
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridToolbar, GridRowParams } from '@mui/x-data-grid';

import MaterialTable from "material-table";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions'; 
import CardActionArea from '@material-ui/core/CardActionArea'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';

import Moment from 'react-moment';
import moment from 'moment';

import { SettingsRemoteRounded } from '@material-ui/icons';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ReactTooltip from 'react-tooltip';

import { Callout, Link, mergeStyleSets, Text, FontWeights } from '@fluentui/react';

import ZStatusEditInputCell from 'components/core/ZStatusEditInputCell';
import ZAddQuotationDialog from 'components/dialogs/ZAddQuotationDialog';
import ZBookToolDialog from 'components/dialogs/ZBookToolDialog';
import ZFluentCalendar from 'components/core/ZFluentCalendar';

//////////////////////////////////////

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  row: {
    //display: 'flex',
    //justifyContent: 'center',
    //flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    margin: 2,
  },
  sensor_row: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: red[100], //'#d47483',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100], //'rgba(157, 255, 118, 0.49)',
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
  avatar: {
    backgroundColor: green[500],
  },
  routes_avatar: {
    backgroundColor: cyan[500],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  status: {
    '& .super-app-theme--cell': {
      backgroundColor: 'rgba(224, 183, 60, 0.55)',
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.negative': {
      //backgroundColor: red[100], //red
      color: '#1a3e72',
      fontWeight: '600',
    },
    '& .super-app.positive': {
      backgroundColor: lightBlue[100],   //green
      color: '#1a3e72',
      fontWeight: '600',
    },
  },
}));

/*
props : {
  parent: {}

}
*/

const styles = mergeStyleSets();

const ZRolesList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  //const [routeId, storeRouteId] = useLocalStorage('route_id', '');
  const [userData, setUserData] = useLocalStorage('userData');
  const [userRoles, setUserRoles] = useLocalStorage('userRoles');

  const [anchorHeader, setAnchorHeader] = React.useState(null);
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: '',
    serverity: 'info',
    error: false,
  });

  const [state, setState] = React.useState({
    activeEntry: undefined,
    showBookingDialog: false,
    showCalendarCallout: false,
  });
  
  //////////////

  let rows = []

  const [updateDeleteResult, deleteFunction] = useMutation(deleteUserRole);
  const [updateUserRoleResult, updateFunction] = useMutation(updateUserRole);

  const handlerUserRoleDelete = React.useCallback(
    ({ id, name }) => {
      console.log('ZRolesList::handlerUserRoleDelete');
      console.log(`role id ${id} - ${name} is going to be removed from user`)

      let activity = {
        user_id: user_id,
        parent_id: id,
        name: "user roles updated",
        description: `${userData.alias} removed role ${name} from user`,         
      }

      deleteFunction( { id: id, user_id: user_id, activity: activity } ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setAlertState({...alertState, message: `ERROR: ${result.error}`, severity: 'error', open: true})

        } else {
          console.log("successfully deleted user_role")
          setAlertState({...alertState, message: "successfully removed role from user", severity: 'info', open: true})
        }
      })
    },
  [deleteFunction]);


  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZRolesList::handleCellEditCommit');
      console.log(id, field, value)

      var _set = {}
      _set[field] = value
      console.log('variables')
      console.log({ id: id, _set })
      const createdOn = moment().format()

      if(field === "is_default"){
        if(value !== "0" && value !== "1"){
          console.log("error - is_default must be 0/1")
          setAlertState({...alertState, message: `ERROR: Is Default must be 0/1`, severity: 'error', open: true})      
          return    
        }
      }

      let activity = {
        user_id: userData.id,
        parent_id: id,
        name: "user role updated",
        description: `${userData.alias} updated user role ${field} to value ${value}`,         
      }

      updateFunction( { id: id, _set, activity } ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setAlertState({...alertState, message: `ERROR: ${result.error}`, severity: 'error', open: true})

        } else {
          console.log("successfully updated user_role")
          setAlertState({...alertState, message: "successfully updated user role", severity: 'info', open: true})
        }
      })
    },
  [updateFunction]);  


  const renderStatusEditInputCell = (params) => {
    return (
      <ZStatusEditInputCell {...params} >
        <MenuItem value="pending">pending</MenuItem>
        <MenuItem value="active">active</MenuItem>
        <MenuItem value="closed">closed</MenuItem>
      </ZStatusEditInputCell>
    );
  }

  let columns = [
    { 
      field: 'id', 
      title: 'Action', 
      headerName: 'Action',
      width: 100,
      editable: 'never',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <div>
        <strong>
        <ButtonGroup size="small">
          <div>        
            <IconButton 
              component="span"
              //variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={(event) => { 
                // delete the user_to_role record
                console.log(`deleting user_to_role link: ${params.row.id}`);
                handlerUserRoleDelete(params.row.id, params.row.role.name);
              }}          
            >
              <ClearIcon />
            </IconButton>
          </div>
          </ButtonGroup>
        </strong>
        </div>
      ),
    },
    {
      field: 'name',
      title: 'Name',
      headerName: 'Name',
      minWidth: 180,
      editable: false,
      hide: false,
      renderCell: (params) => {
        return params.row.role.name
      },     
    },    
    {
      field: 'is_default',
      title: 'Is Default',
      headerName: 'Is Default',
      minWidth: 150,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return params.row.is_default
      },      
    },
    {
      field: 'description',
      title: 'Description',
      headerName: 'Description',
      minWidth: 500,
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return params.row.role.description
      },        
    },
    {
      field: 'status',
      title: 'Status',
      headerName: 'Status',
      renderEditCell: renderStatusEditInputCell,
      minWidth: 120,
      editable: false,   
      sortable: false,
    },
    {
      field: 'createdOn',
      title: 'Date Created',
      headerName: 'Date Created',
      type: 'date',
      minWidth: 200,
      editable: false,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD">
         {params.row.createdOn}
        </Moment>
      ),
    }, 
  ];
  
  const handleCardHeaderClick = (event) => {
    console.log(event)
    setAnchorHeader(event.currentTarget);
  }

  const handleCloseHeader = () => {
    setAnchorHeader(null);
  }

  let headerMenu = (<div></div>)
  if(props.headerMenu){
    headerMenu = (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorHeader}
          keepMounted
          open={Boolean(anchorHeader)}
          onClose={handleCloseHeader}
        >
          <MenuItem onClick={handleCloseHeader}>New Kit</MenuItem>
          <MenuItem onClick={handleCloseHeader}>Something 2</MenuItem>
          <MenuItem onClick={handleCloseHeader}>Something 3</MenuItem>
        </Menu>
      </div>  
    )
  } 

  let user_id = null;
  let skip_query = false;
  let skip_sub = true;

  if(props.static){
    skip_query = true;
  } else {
    user_id = props.user.id
  }

  if(props.realtime !== undefined || props.live !== undefined){
    skip_sub = true;
  }

  let variables = {
    user_id
  }
  
  ///////////////////////////////////
  console.log('ZRolesList->props')
  console.log(props)

  if(props.data){
    rows = props.data
  }

  if(props.items){
    rows = props.items
  }

  const [ result, reexecuteQuery ] = useQuery({
    query: getRolesForUser,
    variables: { user_id },
    pause: skip_query,
  });
  
  //const [updateSensorResult, updateFunction] = useMutation(updateUserRole);
  

  /*
  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      console.log('ZRolesList::handleCellEditCommit');
      console.log(id, field, value)
      var _set = {}
      _set[field] = value
      console.log('variables')
      console.log({ id: id, _set })
      const createdOn = moment().format()

      updateFunction( { id: id, user_id: userData.id, createdOn: createdOn, _set } ).then((result)=>{
        if(result.error){
          console.log(result.error)
          setAlertState({...alertState, message: `ERROR: ${result.error}`, severity: 'error', open: true})

        } else {
          console.log("successfully updated user role")
          setAlertState({...alertState, message: "successfully updated user role", severity: 'info', open: true})
        }
      })
    },
  [updateFunction]);
  */

  ////////////////////
  
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        autoHideDuration={6000}
        open={alertState.open}
        message={alertState.message}
        key="alert_bar"
        onClose={()=>{
          console.log('trying to open snackbar')
          //setOpenAlert(false)
        }}
        action={
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
              onClick={()=>{
                console.log('trying to close snackbar')
                //setOpenAlert(false)
                setAlertState({
                  open: false,
                  message: ''
                })
              }}
            >
              Close
            </Button>
          </div>
        }
      />       
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="roles" className={classes.routes_avatar}>
              <PolicyIcon />
            </Avatar>
          }      
          action={props.action}          
          title={props.title ? props.title : 'User Roles'}
          titleTypographyProps={{variant:'h5'}}
        />
        <CardContent>
          <Paper id='role-data-grid' className={classes.sensor_row}>
            <DataGrid autoHeight
              rows={rows}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              getRowClassName={(params) =>
                clsx('super-app', {
                  negative: params.row.status !== 'active',
                  positive: params.row.status === 'active',
                })
              }
              options={{
                toolbar: true,
                filtering: true,
                search: true,
                grouping: true,
                exportButton: true,
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              onCellEditCommit={handleCellEditCommit}
            />
          </Paper>
        </CardContent>
        <ReactTooltip id="roles_list_tooltip"/>
      </Card>
    </div>
  ); 
}
  
export default ZRolesList;
